import { AxiosError, AxiosResponse } from 'axios';
import { Middleware } from 'redux';
import axiosRetry from 'axios-retry';
import { logger, httpClient } from '../../services';
import { RootState } from '../../interfaces';
import { setErrorMessage, setGameBoardState } from '../actions/eventData';
import { GameBoardState } from '../../constants/enums';

const promiseMiddleware: Middleware<{}, RootState> = () => (next) => {
  const formatTypeName = (type: string): string => type.split('_REQUEST').join('');
  return (action): void => {
    const {
      useApi, type, retry, ...rest
    } = action;

    next({ ...rest, type });

    const onSuccess = ({ data, ...meta }: AxiosResponse): void => {
      const successType = `${formatTypeName(type)}_SUCCESS`;
      next({
        ...rest,
        payload: data,
        meta,
        type: successType,
      });
      next(setGameBoardState(GameBoardState.ACTIVE));
    };

    const onError = (error: AxiosError): void => {
      const failedType = `${formatTypeName(type)}_FAILURE`;
      next({ ...rest, error, type: failedType });
      next(setErrorMessage(`API request ${type} failed. Error: ${error.message}`));
      next(setGameBoardState(GameBoardState.ERROR));
    };

    if (useApi) {
      httpClient.defaults.params.t = RSIUtils.requestTimestamp();
      if (retry) {
        axiosRetry(httpClient, {
          retries: retry.retryNumber,
          retryDelay: (retryCount) => {
            logger.warn(`API call failed, retyring. retry attempt: ${retryCount}`);
            return retryCount * retry.intervalLength;
          },
          retryCondition: (result) => {
            const status = result?.response?.status;
            if (status) { return !(status >= 200 && status < 300); }
            return true;
          },

        });
      }

      useApi(httpClient).then(onSuccess, onError);
    }
  };
};

export default promiseMiddleware;
