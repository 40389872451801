import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  awayTeamSelector, eventScoreSelector, homeTeamSelector, isGameCompletedSelector,
} from '../../../store/selectors/eventDataSelector';
import Translations from '../../../constants/translations';

const Container = styled.div<{ isGameCompleted: boolean, isNeedsResizing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  .team-name {
    font-size: ${({ isNeedsResizing, theme }) => (isNeedsResizing ? theme.fontSize.regular : theme.fontSize.medium)};
    overflow: hidden;
    flex: 1;

    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .away-team-name {
    text-align: end;
    margin-right: 4px;
  }

  .home-team-name {
    text-align: start;
    margin-left: 8px;
  }

  .team-score {
    font-size: 42px;
    line-height: 130%;
    font-weight: 700;
  }

  .team-score-last-digit {
    text-decoration: underline;
    text-decoration-color: ${({ isGameCompleted, theme }) => (isGameCompleted ? theme.colors.tab.underline : theme.colors.eventScore.liveBackground)};
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
    border-radius: 8px;
  }
`;

const Live = styled.span<{ isGameCompleted: boolean }>`
    font-size: ${({ theme, isGameCompleted }) => (isGameCompleted ? theme.fontSize.medium : theme.fontSize.small)};
    font-weight: bold;
    font-style: ${({ isGameCompleted }) => (isGameCompleted ? 'normal' : 'italic')};
    width: 29px;
    text-align: center;
    background-color: ${({ theme, isGameCompleted }) => (isGameCompleted ? 'transparent' : theme.colors.eventScore.liveBackground)};
    border-radius: 2px;
    margin: 0px 12px;
`;

function TeamScore({ name, score, home }: { name: string, score: string, home: boolean }) {
  const lastDigit = String(score).slice(-1);
  const digitsBefore = String(score).slice(0, -1);

  if (home) {
    return (
      <>
        <span className="team-score">{digitsBefore}</span>
        <span className="team-score team-score-last-digit">{lastDigit}</span>
        <span className="team-name home-team-name">{name}</span>
      </>
    );
  }
  return (
    <>
      <span className="team-name away-team-name">{name}</span>
      <span className="team-score">{digitsBefore}</span>
      <span className="team-score team-score-last-digit">{lastDigit}</span>
    </>
  );
}

function EventScore() {
  const { homeScore, awayScore } = useSelector(eventScoreSelector);
  const isGameCompleted = useSelector(isGameCompletedSelector);
  const homeTeam = useSelector(homeTeamSelector)!;
  const awayTeam = useSelector(awayTeamSelector)!;
  const isNeedsResizing = (homeTeam.name.length > 15 || awayTeam.name.length > 15);
  const intl = useIntl();

  return (
    <Container isGameCompleted={isGameCompleted} isNeedsResizing={isNeedsResizing}>
      <TeamScore name={awayTeam.name} score={awayScore} home={false} />
      <Live isGameCompleted={isGameCompleted} data-testid="event-score-live-icon">
        {isGameCompleted ? '@'
          : intl.formatMessage(Translations.LIVE)}
      </Live>
      <TeamScore name={homeTeam.name} score={homeScore} home />
    </Container>
  );
}

export default EventScore;
