import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  isGameCompletedSelector,
  isWinnerSelector,
} from '../../../../store/selectors/eventDataSelector';
import { StickyHeader } from '../../../StyledElements';
import TabContent from './TabContent';
import TabTitle from './TabTitle';
import { TabType } from '../../../../constants/enums';

const footerTabs = [TabType.BOOSTS, TabType.PRIZES];

const TabHeaders = styled(StickyHeader)`
  display: flex;
  flex-direction: row;
  top: 48px;
  height: 40px;
  backdrop-filter: blur(8px);
  justify-content: center;
  gap: 16px;
`;

function Tabs() {
  const [currentTab, setCurrentTab] = useState(TabType.BOOSTS);
  const isGameCompleted = useSelector(isGameCompletedSelector);
  const isWinner = useSelector(isWinnerSelector);

  useEffect(() => {
    if (isWinner) {
      setCurrentTab(TabType.PRIZES);
    } else if (isGameCompleted) {
      setCurrentTab(TabType.NOT_WIN);
    }
  }, [isGameCompleted, isWinner]);

  const handleTabClick = (e: React.MouseEvent, tab: TabType) => {
    e.stopPropagation();
    if (tab !== currentTab) {
      setCurrentTab(tab);
    }
  };

  if (TabType.NOT_WIN === currentTab) {
    return <TabContent tab={currentTab} />;
  }

  return (
    <>
      <TabHeaders>
        {footerTabs.map((tab) => (
          <TabTitle
            key={tab}
            onClick={(e) => handleTabClick(e, tab)}
            isCurrent={currentTab === tab}
            tab={tab}
          />
        ))}
      </TabHeaders>
      <TabContent tab={currentTab} />
    </>
  );
}

export default Tabs;
