import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CrossCircleIconSvg } from '../../assets/svgs/xmarkCircle.svg';
import { StickyHeader } from '../StyledElements';
import { LayoutState } from '../../constants/enums';
import { setLayoutState } from '../../store/actions/appConfig';
import { layoutStateSelector } from '../../store/selectors/appConfigSelector';
import ModalIconButton from '../Modal/ModalIconButton';
import { closeGame } from '../../utils/eventBus';

const ModalHeaderCtn = styled(StickyHeader)<{isRulesOnly: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    top: ${(props) => (props.isRulesOnly ? '15px' : '0px')};
    svg {
      height: 20px;
    }
  `;
function RuleModalHeader() {
  const layoutState = useSelector(layoutStateSelector);
  const isRulesOnly = layoutState === LayoutState.RuleOnly;
  const dispatch = useDispatch();
  const handleModalClose = () => {
    if (layoutState === LayoutState.Rule) {
      dispatch(setLayoutState(LayoutState.Game));
    }
    if (isRulesOnly) {
      closeGame();
    }
  };

  return (
    <ModalHeaderCtn isRulesOnly={isRulesOnly}>
      <ModalIconButton onClick={handleModalClose} aria-label="Close" data-testid="rule-modal-header-icon">
        <div className="close-button">
          <CrossCircleIconSvg />
        </div>
      </ModalIconButton>
    </ModalHeaderCtn>
  );
}

export default RuleModalHeader;
