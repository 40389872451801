export default {
  REACT_APP_TAG: process.env.REACT_APP_TAG,
  REACT_APP_IDENTIFIER: process.env.REACT_APP_IDENTIFIER,
  REACT_APP_TEAM: process.env.REACT_APP_TEAM,
  REACT_APP_AXIOS_BASE_URL: process.env.REACT_APP_AXIOS_BASE_URL,
  REACT_APP_CDN_URL: process.env.REACT_APP_CDN_URL,
  PUBLIC_URL: process.env.PUBLIC_URL,
  PROMO_BTN_SECTION_ID: process.env.REACT_APP_PROMO_SECTION_ID,
  FULL_CDN_URL: `${process.env.REACT_APP_CDN_URL}/${process.env.REACT_APP_IDENTIFIER}/${process.env.REACT_APP_CDN_VERSION}`,
};
