import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlayerExtraFields } from '../store/actions/eventData';
import { getStorageSessionId } from '../utils/squareApi';
import { eventIdSelector } from '../store/selectors/eventDataSelector';
import useConfigs from './useConfigs';

const useGetPlayerExtraFields = () => {
  const { api } = useConfigs(['api']);
  const eventId = useSelector(eventIdSelector);
  const sessionId = getStorageSessionId();

  const dispatch = useDispatch();
  useEffect(() => {
    if (sessionId && eventId && api) {
      dispatch(fetchPlayerExtraFields(sessionId, api.apiUrl));
    }
  }, [dispatch, sessionId, eventId, api]);
};

export default useGetPlayerExtraFields;
