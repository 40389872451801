import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { logger } from '../services';
import Translations from '../constants/translations';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const Container = styled.div`
    background: ${({ theme }) => theme.colors.backgroundColor};
    height: 100vh;
    color: ${({ theme }) => theme.colors.headerTextColor};
    font-size: ${({ theme }) => theme.fontSize.massive};
    display: flex;
    justify-content: center;
    padding-top: 40vh;
`;
export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Error caught: ', {
      error: error?.message,
      errorInfo,
    });
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    // do not render the component on error
    if (hasError) {
      const errorLoadingTranslation: MessageDescriptor = Translations.ERROR_LOADING;
      return (
        <Container>
          <FormattedMessage
            id={errorLoadingTranslation.id}
            defaultMessage={errorLoadingTranslation.defaultMessage}
          />
        </Container>
      );
    }
    // Normally, just render children
    return children;
  }
}
