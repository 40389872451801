import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../services';
import { fetchSquaresGameById } from '../store/actions/eventData';
import { squareGameIdSelector } from '../store/selectors/appConfigSelector';
import { getStorageSessionId } from '../utils/squareApi';
import useConfigs from './useConfigs';

const useSquareGameData = () => {
  const { api } = useConfigs(['api']);
  const squareGameId = useSelector(squareGameIdSelector);
  const sessionId = getStorageSessionId();

  const dispatch = useDispatch();
  useEffect(() => {
    if (api) {
      if (sessionId && squareGameId) {
        dispatch(fetchSquaresGameById(squareGameId, sessionId, api.apiUrl));
      } else {
        logger.error('Missing session ID or square game ID in SquareGame', {
          sessionId,
          squareGameId,
        });
        throw new Error('Missing session ID or square game ID in SquareGame');
      }
    }
  }, [dispatch, sessionId, squareGameId, api]);
};

export default useSquareGameData;
