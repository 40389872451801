import { useEffect } from 'react';
import { logger } from '../services';
import { PortalWssResponse } from '../interfaces';
import useConfigs from './useConfigs';
import {
  AwardedSquareNotification,
  Square,
  SquareAwardParameters,
} from '../interfaces/EventDataState';
import { getStorageSessionId } from '../utils/squareApi';
import { PROMOTION_AWARD } from '../constants';

let subscription: { unsubscribe: Function, send: Function };

const { eventName, squaresPromoType } = PROMOTION_AWARD;

const wsConfig = {
  messages: [eventName],
  onOpen: () => { },
  onClose: () => { },
};

/**
 * handler for received square promotion awarded
 * @param promotionAwardEvent: event data
 * @param performAction: action to perform
 * @param gameBoardGameId: (optional) does it have to match it with current game board id if present
 */
export const onPromotionAwardReceived = (
  promotionAwardEvent: AwardedSquareNotification,
  performAction: (awardParameters: SquareAwardParameters, squares: Square[]) => void,
  gameBoardGameId?: number,
) => {
  const { awardParameters } = promotionAwardEvent;
  const { squares, gameId } = awardParameters as SquareAwardParameters;

  const isValid = () => {
    const { providerPromotionTypeCode } = promotionAwardEvent;
    if (providerPromotionTypeCode !== squaresPromoType) return false;
    if (!squares) return false;
    return !(gameBoardGameId && Number(gameId) !== gameBoardGameId);
  };

  if (!isValid()) return;

  try {
    const squaresArray: Square[] = JSON.parse(squares);
    if (squaresArray?.length > 0) {
      logger.info('Squares awarded to player: ', squares);
      performAction(awardParameters as SquareAwardParameters, squaresArray);
    } else if (!squaresArray || squaresArray.length === 0) {
      logger.error('No Squares were awarded to player for game : ', gameId);
    }
  } catch (e) {
    logger.error('Failed to parse squares string: ', squares, ' to array');
  }
};

const raiseEventBusEvent = (awardParameters: SquareAwardParameters, squares: Square[]) => {
  const {
    gameId,
    name,
    eventId,
  } = awardParameters;
  RSIEventBus.publish(
    RSIEventBus.eventTypes.SQUARES_GAME_PROMOTION_AWARDED,
    {
      eventId,
      gameId,
      name,
      squares,
    },
    {
      toNative: false,
      withHistory: true,
    },
  );
};

/**
 * This call back is part of the portalWS subscription which handles Squares
 * specific event notifications
 * @callback onMessage
 * @param onAward
 */
const useSquarePromoAwardWS = (
  onAward: (
    promoAwardWithCountOfSquares: SquareAwardParameters & { countOfSquaresAwarded: number },
  ) => void,
) => {
  const { api } = useConfigs(['api']);
  const sessionId = getStorageSessionId(api?.cage);
  useEffect(() => {
    if (api) {
      const { wssUrl } = api;
      const onMessage = (payload: PortalWssResponse) => {
        const { type, data } = payload;
        if (type !== eventName) return;

        const notificationData = data as AwardedSquareNotification;
        const handleAward = (
          awardParameters: SquareAwardParameters,
          squaresArray: Square[],
        ) => {
          onAward({
            ...awardParameters,
            countOfSquaresAwarded: squaresArray.length,
          });

          raiseEventBusEvent(awardParameters, squaresArray);
        };
        onPromotionAwardReceived(notificationData, handleAward);
      };

      // @ts-ignore
      window.onSquareBtnMessage = onMessage;
      try {
        subscription?.unsubscribe();
        if (sessionId) {
          subscription = RSIWebSocket.subscribe({
            ...wsConfig,
            url: wssUrl + sessionId,
          }, onMessage);
        }
      } catch (error) {
        // @ts-ignore Rollup build complain somehow, TS2554: Expected 0-1 arguments, but got 2
        logger.error('Error in setup portal wss subscription: ', { cause: error as Error });
      }
    }
  }, [api, onAward, sessionId]);

  useEffect(
    () => () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    },
    [],
  );
};

export default useSquarePromoAwardWS;
