import styled from 'styled-components';

// PCON-1233 CellContainer style below was removed from this ticket
// Somehow z-index: -1 causing issue in IOS app, make the board disappear
// Leave the code here for reference
// export const CellContainer = styled.div`
//   border-radius: 8px;
//   mask: linear-gradient(black 0 0);
//   margin: 0.5px;
//   ::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: -1;
//     background:
//       linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.3)),
//       url(${boardBgImg}) center/cover;
//   }
// `;

export const CellContainer = styled.div`
  margin: 0.5px;
  .cell-top {
    border-radius: 10px 10px 6px 6px;
  }
  .cell-bottom {
    border-radius: 6px 6px 10px 10px;
  }
  .cell-left.cell-top {
    border-radius: 10px 10px 6px 10px;
  }
  .cell-right.cell-top {
    border-radius: 10px 10px 10px 6px;
  }
  .cell-left.cell-bottom {
    border-radius: 10px 6px 10px 10px;
  }
  .cell-right.cell-bottom {
    border-radius: 6px 10px 10px 10px;
  }
`;

export const NormalCell = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cell.boarder};
  border-radius: 8px;
  height: 100%;
  width: 100%;
`;

export const FinalScoreCell = styled(NormalCell)`
  background-color: ${({ theme }) => theme.colors.tab.underline};
  border-color: ${({ theme }) => theme.colors.tab.underline};
  text-align: center;
  svg {
    height: 100%;
    width: 100%;
    margin-bottom: -20%;
  }
`;

export const CurrentScoreCell = styled(NormalCell)`
  background-color: ${({ theme }) => theme.colors.cell.background.live};
  border-color: ${({ theme }) => theme.colors.cell.background.live};
  font-style: italic;
  font-weight: 400;
  font-family: THECHAMP DEMO;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserAwardedCell = styled(NormalCell) <{ isLive?: boolean, isFinalScore: boolean }>`
  background-color: ${({ theme, isLive, isFinalScore }) => {
    const { background } = theme.colors.cell;
    if (isFinalScore) return background.userAwardedAndWinning;
    if (isLive) return background.userAwardedAndLive;
    return background.userAwarded;
  }};
  border-color: ${({ theme, isLive, isFinalScore }) => {
    const { background } = theme.colors.cell;
    if (isFinalScore) return background.userAwardedAndWinning;
    if (isLive) return background.userAwardedAndLive;
    return background.userAwarded;
  }};
  color: ${({ theme, isLive, isFinalScore }) => {
    const { text } = theme.colors.cell;
    if (isFinalScore) return text.userAwardedAndWinning;
    if (isLive) return text.live;
    return text.userAwarded;
  }};
  position: relative;

  .homeTeamScore{
    position: absolute;
    right: 12%;
    font-weight: 700;
  }
  .awayTeamScore{
    position: absolute;
    bottom: -1px;
    left: 12.5%;
    font-weight: 700;
  }
  .divider{
    border-bottom: 1px solid ${({ theme, isLive, isFinalScore }) => {
    const { dividerLine } = theme.colors.cell;
    if (isFinalScore) return dividerLine.userAwardedAndWinning.color;
    if (isLive) return dividerLine.live.color;
    return dividerLine.normal.color;
  }};
    opacity: ${({ theme, isLive }) => (isLive ? theme.colors.cell.dividerLine.live.opacity : theme.colors.cell.dividerLine.normal.opacity)};
    width: 150%;
    transform: rotate(45deg);
    transform-origin: top left;
  }
`;
