import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isGameCompletedSelector } from '../../../store/selectors/eventDataSelector';
import { boardSizeArray } from './Board';

export const Coordinate = styled.div`
  flex:1;
  text-align: center;
`;

const CoordinateLive = styled(Coordinate) <{ isGameCompleted: boolean }>`
  color: ${({ theme, isGameCompleted }) => (isGameCompleted ? theme.colors.tab.underline : theme.colors.eventScore.liveBackground)};
`;

export function Coordinates({ liveScore }: {
  liveScore: number | string | undefined
}) {
  const isGameCompleted = useSelector(isGameCompletedSelector);
  const score = ((typeof liveScore === 'string' ? Number(liveScore) : liveScore) || 0) % 10;

  return (
    <>
      {boardSizeArray.map(
        (scoreLastDigit) => {
          if (scoreLastDigit === score) {
            return (
              <CoordinateLive
                key={scoreLastDigit}
                isGameCompleted={isGameCompleted}
              >
                {scoreLastDigit}
              </CoordinateLive>
            );
          }
          return <Coordinate key={scoreLastDigit}>{scoreLastDigit}</Coordinate>;
        },
      )}
    </>
  );
}

export default Coordinates;
