import React from 'react';
import styled from 'styled-components';
import SquarePromoBtn from './SquarePromoBtn';
import { useTranslations } from '../../hooks';
import { TranslationType } from '../../constants/enums';
import { SquarePromoItem } from '../../interfaces';

const SectionTitle = styled.div`
  margin-bottom: 8px;
`;

function SquarePromoList({ squareGamePromotions }: { squareGamePromotions: SquarePromoItem[] }) {
  const squareGameTranslations = useTranslations(TranslationType.SquareGame);
  const SQUARES_GAME = squareGameTranslations?.SQUARES_GAME || 'Squares Game';

  return (
    <>
      <SectionTitle>{SQUARES_GAME}</SectionTitle>
      <div>
        {squareGamePromotions.map((sq) => <SquarePromoBtn key={sq.gameId} square={sq} />)}
      </div>
    </>
  );
}

export default SquarePromoList;
