// Footer
// in game: a component with Tabs (tab headers and Tab content)
// out game: a component with info (you are a winner)
import React from 'react';
import Tabs from './Tabs';

function Footer() {
  return (
    <Tabs />
  );
}

export default Footer;
