import React from 'react';
import { useSelector } from 'react-redux';
import { isGameStartedSelector } from '../../../store/selectors/eventDataSelector';
import EventName from './EventName';
import EventScore from './EventScore';

function Header() {
  const isMatchStarted = useSelector(isGameStartedSelector);
  if (isMatchStarted) {
    return <EventScore />;
  }
  return <EventName />;
}

export default Header;
