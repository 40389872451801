import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gameBoardStateSelector, gameErrorMessageSelector } from '../store/selectors/eventDataSelector';
import { GameBoardState } from '../constants/enums';

// This hook is to throw error and let ErrorBoundary catch
const useErrorState = () => {
  const hasError = useSelector(gameBoardStateSelector) === GameBoardState.ERROR;
  const errorMessage = useSelector(gameErrorMessageSelector);

  useEffect(() => {
    if (hasError) {
      // logger in ErrorBoundary will log it
      throw new Error(`${errorMessage}`);
    }
  }, [hasError, errorMessage]);
};

export default useErrorState;
