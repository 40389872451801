import React from 'react';
import ErrorBoundary from '../ErrorBoundary';
import AppLayout from './AppLayout';

function Layout() {
  return (
    <ErrorBoundary>
      <AppLayout />
    </ErrorBoundary>
  );
}

export default Layout;
