import { createSelector } from 'reselect';
import { GameBoardState, SquareGameStatus } from '../../constants/enums';
import {
  EventScore,
  RootState,
} from '../../interfaces';
import { EventDataState, Participant } from '../../interfaces/EventDataState';
import { isAwardedSquaresMatchScore } from '../../utils';

const selectSelf = (state: RootState) => state.eventData;

export const matchClockSelector = createSelector(
  selectSelf,
  (eventData) => eventData.matchClock,
);

export const eventScoreSelector = createSelector(
  selectSelf,
  (eventData) => eventData.eventScore || { homeScore: '0', awayScore: '0' } as EventScore,
);

const gameSelector = createSelector(
  selectSelf,
  (eventData) => eventData.squareGame!,
);

export const rewardsSelector = createSelector(
  gameSelector,
  (squareGame) => [squareGame.reward],
);

export const gameBoardStateSelector = createSelector(
  selectSelf,
  (event) => event.gameBoardState,
);
export const isGameBoardLoadingSelector = createSelector(
  gameBoardStateSelector,
  (boardStatus) => boardStatus === GameBoardState.LOADING,
);
export const gameErrorMessageSelector = createSelector(
  selectSelf,
  (event) => event.errorMessage,
);

export const cellsSelector = createSelector(
  selectSelf,
  (eventData) => eventData.cells,
);

export const eventIdSelector = createSelector(
  gameSelector,
  (game) => game?.eventId,
);

export const isBoostActiveSelector = createSelector(
  selectSelf,
  (eventData: EventDataState) => eventData.isBoostActive,
);
export const eventNameSelector = createSelector(
  gameSelector,
  (game) => game.name,
);
export const eventStartTimeSelector = createSelector(
  gameSelector,
  (game) => game.startsOn,
);
export const homeTeamSelector = createSelector(
  gameSelector,
  (game) => game.participants.find((p: Participant) => p.home),
);
export const awayTeamSelector = createSelector(
  gameSelector,
  (game) => game.participants.find((p: Participant) => !p.home),
);

export const cellDataSelector = createSelector(
  [
    cellsSelector,
    (_: RootState, coordinate: string) => (coordinate),
  ],
  (cells, coordinate) => {
    const cell = cells![coordinate];
    const emptyCell = {
      isCurrentScore: false,
      isUserAwardedCell: false,
      isWinner: false,
      isFinalScore: false,
    };
    return { ...emptyCell, ...cell };
  },
);

const squaresSelector = createSelector(
  gameSelector,
  (game) => game.squares,
);

const winningSquareSelector = createSelector(
  selectSelf,
  (eventData) => eventData.winningSquare,
);

export const gameStatusSelector = createSelector(
  gameSelector,
  (game) => game?.status,
);

export const gameRulesSelector = createSelector(
  gameSelector,
  (game) => game?.rules,
);

export const isGameCompletedSelector = createSelector(
  gameStatusSelector,
  (status) => status === SquareGameStatus.Completed,
);

export const isGameStartedSelector = createSelector(
  gameStatusSelector,
  (status) => [SquareGameStatus.Started, SquareGameStatus.Completed].includes(status),
);

export const isWinnerSelector = createSelector(
  isGameCompletedSelector,
  squaresSelector,
  eventScoreSelector,
  winningSquareSelector,
  (isGameCompleted, squares, latestScore, winningSquare) => {
    if (isGameCompleted) {
      return isAwardedSquaresMatchScore(squares, latestScore, winningSquare);
    }
    return false;
  },
);
