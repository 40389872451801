import { useEffect, useState } from 'react';
import useConfigs from './useConfigs';

interface LoginStatusChangeEventParams {
  reason: string;
  onLogin?: (apiUrl: string) => any;
  onLogout?: Function;
}

const subscriberMap: { [key: string]: EventBusSubscriber } = {};
/**
 * Set up event listener for login status change
 */
const useLoginStatusChangeEvent = (
  { reason, onLogin, onLogout }: LoginStatusChangeEventParams,
) => {
  const { api } = useConfigs(['api']);
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (api) {
      const { apiUrl } = api;
      const onLoginStatusChange = (payload: { isLoggedIn: boolean; sessionId: string }) => {
        if (payload.isLoggedIn) {
          if (onLogin && loggedIn !== payload.isLoggedIn) {
            onLogin(apiUrl);
          }
        } else if (onLogout) {
          onLogout();
        }
        setLoggedIn(payload.isLoggedIn);
      };

      const subscriber = subscriberMap[reason];
      if (subscriber) {
        subscriber.unsubscribe();
      }
      subscriberMap[reason] = RSIEventBus.subscribe(
        RSIEventBus.eventTypes.PORTAL_LOGIN_STATE_CHANGE,
        onLoginStatusChange,
      );
    }
    return () => {
      subscriberMap[reason]?.unsubscribe();
    };
  }, [loggedIn, onLogin, onLogout, reason, api]);
};

export default useLoginStatusChangeEvent;
