/* eslint-disable react/require-default-props, react/jsx-props-no-spreading */
import React from 'react';
import { IconButton } from '../StyledElements';

interface ModalIconButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  onClick?: Function;
}

function ModalIconButton({
  onClick, children, ...otherProps
}: ModalIconButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <IconButton
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </IconButton>
  );
}

export default ModalIconButton;
