import {
  AnyAction,
  applyMiddleware,
  // TODO: SPORT-3095 migrate to @reduxjs/toolkit configureStore
  legacy_createStore as createStore,
  compose,
} from 'redux';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { PartialRootState } from '../interfaces';
import { logger } from '../services';
import rootReducer from './reducers';
import { promiseMiddleware, gameConfirmMiddleware } from './middleware';
import actionsLogAllowlist from './actionsLogAllowlist';
import env from '../utils/env';

const store = (initialState?: PartialRootState) => {
  const loggerMiddleware = createLogger({
    logger,
    colors: false,
    level: {
      prevState: 'trace',
      action: ({ type }) => {
        if (type.includes('_FAILURE')) {
          return 'error';
        }
        return 'debug';
      },
      nextState: 'trace',
      error: 'error',
    },
    titleFormatter: (action: AnyAction, time: string, took: number) => `action @ ${time} ${action.type} (in ${took.toFixed(2)} ms)`,
    actionTransformer: (action: AnyAction) => {
      if (action.type.includes('_SUCCESS')) {
        return { type: action.type };
      }
      return action;
    },
    predicate: (_getState, { type }) => actionsLogAllowlist.includes(type) || type.includes('_FAILURE'),
  });

  const middleware = applyMiddleware(
    reduxThunk,
    promiseMiddleware,
    gameConfirmMiddleware,
    loggerMiddleware,
  );

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    const options = {
      name: env.REACT_APP_IDENTIFIER || 'APP_MISSING_NAME',
    };
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options) || composeEnhancers;
  }

  return createStore(rootReducer, initialState, composeEnhancers(middleware));
};

export default store;
