import EventDataActionTypes from './actionTypes/eventData';

/**
 * Action names added here will be logged to Splunk and console.
 * Example value: [ExamplesActionTypes.GET_EXAMPLE_REQUEST]
 */

const allowlist: string[] = [
  EventDataActionTypes.EVENT_NOTIFICATION_GAME_STATUS_CHANGE,
  EventDataActionTypes.FETCH_SQUARES_GAME_BY_ID_SUCCESS,
];

export default allowlist;
