import { TranslationType } from '../constants/enums';
import useConfigs from './useConfigs';
import useLocale from './useLocale';

const useTranslations = (translationType: TranslationType)
  : { [key: string]: string } | undefined => {
  const { language } = useLocale();

  const data = useConfigs([`${translationType}-${language || 'ENG'}`]) as { [key: string]: any };
  return data[`${translationType}-${language || 'ENG'}`];
};

export default useTranslations;
