export enum SquareGameStatus {
  Scheduled = 'SCHEDULED',
  Available = 'AVAILABLE',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Postponed = 'POSTPONED',
  Cancelled = 'CANCELLED'
}

// RUNNING - clock is running
// STOPPED - clock is stopped
// DISABLED - clock is stopped(seems to be used between periods instead of STOPPED in some games)
export enum MatchStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Disabled = 'DISABLED'
}

export enum TranslationType {
  SquareGame = 'squareGameTranslations'
}

export enum BoostType {
  LOTTERY = 'LOTTERY'
}

export enum LayoutState {
  Game = 'GAME',
  Rule = 'RULE',
  RuleOnly = 'RULE_ONLY'
}

export enum GameBoardState {
  LOADING = 'LOADING',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR'
}

export const enum TabType {
  BOOSTS = 'BOOSTS',
  PRIZES = 'PRIZES',
  NOT_WIN = 'NOT_WIN',
}
