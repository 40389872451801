import React, { lazy, Suspense } from 'react';
import { useIntl } from 'react-intl';
import Translations from '../../../../constants/translations';
import { TabType } from '../../../../constants/enums';

const NotWinnerItem = lazy(() => import('../footerItem/NotWinnerItem'));
const Boosts = lazy(() => import('./Boosts'));
const Prizes = lazy(() => import('./Prizes'));

function TabContent({ tab }: { tab: TabType }) {
  const intl = useIntl();
  let child = null;
  switch (tab) {
    case TabType.PRIZES: {
      child = <Prizes />;
      break;
    }
    case TabType.BOOSTS: {
      child = <Boosts />;
      break;
    }
    case TabType.NOT_WIN: {
      child = <NotWinnerItem />;
      break;
    }
    default: break;
  }
  return (
    <Suspense fallback={<div>{intl.formatMessage(Translations.TAB_LOADING)}</div>}>
      {child}
    </Suspense>
  );
}

export default TabContent;
