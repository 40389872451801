import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import RuleModalHeader from './RuleModalHeader';
import { layoutStateSelector } from '../../store/selectors/appConfigSelector';
import { LayoutState } from '../../constants/enums';
import { useConfigs } from '../../hooks';
import BackOfficeContent from './BackofficeContent';
import rulesBg from '../../assets/images/rulesBg.jpg';

const RuleModal = styled.div<{ calcModalHeight: string, calcContentHeight: string, isRuleOnly: boolean, isDesktop: boolean, isAndroid: boolean }>`
  position: absolute;
  ${({ calcModalHeight }) => (
    calcModalHeight === '100vh'
      ? 'top: 0'
      : 'bottom: 0; border-radius: 20px 20px 0 0')};
  height: ${({ calcModalHeight }) => calcModalHeight};
  padding: 0px 16px;
  transition: height 0.3s;
  color: ${({ theme }) => theme.colors.textColor};
  background: url(${rulesBg});
  background-attachment: local;
  background-repeat: no-repeat;
  ${({ isRuleOnly }) => !isRuleOnly && 'margin-left: -2%;'}
  ${({ isRuleOnly, isAndroid }) => (isAndroid && isRuleOnly) && 'height: 100vh;'}
  background-color: ${({ theme }) => theme.colors.rule.background};
  background-size: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .modal-content-container {
    height: ${({ calcContentHeight }) => calcContentHeight};
  }

  .rules-title-container {
    font-style: italic;
    line-height: 52px;
    font-weight: bold;
  }
  .rules-title-gold {
    color: ${({ theme }) => theme.colors.rule.title};
    font-size: ${({ theme }) => theme.fontSize.heroSmall};
    border-bottom: 0px;
    margin: 0px;
    ${({ isDesktop }) => !isDesktop && 'line-height: 1em;'};
  }
  .rules-title-white{
    color: ${({ theme }) => theme.colors.headerTextColor};
    font-size: ${({ theme }) => theme.fontSize.heroMedium};
    border-bottom: 0px;
    margin: 0px;
    ${({ isDesktop }) => !isDesktop && 'line-height: 1em;'};
  }

  ${({ isDesktop }) => !isDesktop && 'h3 { line-height: 1.2em}; h2 { border-bottom: 0px;}'}
`;

const { isDesktop, isAndroid } = RSIUtils.detector;

function getHeight(layoutState: LayoutState) {
  const determinedViewHeight = isDesktop ? '78vh' : '100vh';
  if (layoutState === LayoutState.Rule) {
    return [`calc(${determinedViewHeight} - 80px)`, `calc(${determinedViewHeight} - 128px)`];
  }
  if (layoutState === LayoutState.RuleOnly) {
    if (isAndroid) {
      document.getElementById('rsi-squares-game')!.setAttribute('style', 'height:100vh');
    }
    return [isDesktop ? '76vh' : '93vh', 'calc(76vh - 64px)'];
  }
  return ['0vh', '0vh'];
}

function RuleLayout() {
  const { squares } = useConfigs(['squares']);
  const { api } = useConfigs(['api']);
  const layoutState = useSelector(layoutStateSelector);
  const [modalHeight, contentHeight] = getHeight(layoutState);
  let interpolatedCMSCopy = '';
  if (squares?.configuration && api) {
    interpolatedCMSCopy = squares!.configuration.gameRules.replaceAll('{{baseUrl}}', api?.baseUrl);
  }
  if (layoutState === LayoutState.Game) {
    return null;
  }
  return (
    <RuleModal
      calcModalHeight={modalHeight}
      calcContentHeight={contentHeight}
      isDesktop={isDesktop}
      data-testid={`rule-modal-${layoutState}`}
      isRuleOnly={layoutState === LayoutState.RuleOnly}
      isAndroid={isAndroid}
    >
      <RuleModalHeader />
      <div className="modal-content-container">
        <div className="rules-title-container">
          <h1 className="rules-title-gold">HOW TO</h1>
          <h1 className="rules-title-white">SQUARES</h1>
          <h1 className="rules-title-white">GAME</h1>
        </div>
        <BackOfficeContent />
        {squares?.configuration
          // eslint-disable-next-line react/no-danger
          && <div dangerouslySetInnerHTML={{ __html: interpolatedCMSCopy }} />}
      </div>
    </RuleModal>
  );
}

export default RuleLayout;
