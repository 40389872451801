import { AnyAction } from 'redux';
import {
  EventDataState,
  GameStatusChangeNotification,
  GameStatusChangeToCompleted,
  Square,
  SquareGame,
} from '../../interfaces/EventDataState';
import { EventDataActionTypes } from '../actionTypes';
import { getInitScore, getLastCompletedInterval } from '../../utils';
import { GameBoardState, SquareGameStatus } from '../../constants/enums';
import { EventScoreResponse } from '../../interfaces';
import {
  gameCompletedStateUpdates,
  mergeCells,
  updateAwardedCell,
  updateLiveCell,
} from './updateGameCells';
import Score from '../../utils/score';
import { logger } from '../../services';
import { SQUARES_LOTTERY_BOOST_PREFIX } from '../../constants';

const initialState: EventDataState = {
  gameBoardState: GameBoardState.LOADING,
};

export default (
  state = initialState,
  {
    type,
    payload,
  }: AnyAction = { type: EventDataActionTypes.DEFAULT },
) => {
  switch (type) {
    case EventDataActionTypes.SET_EVENT_SCORE: {
      if (state.squareGame?.status !== SquareGameStatus.Started) return state;

      const { eventScore: latestScore } = payload as EventScoreResponse;
      const cellsToUpdate = updateLiveCell(latestScore, state.eventScore);
      const updatedCells = mergeCells(cellsToUpdate, state.cells);
      return {
        ...state,
        eventScore: latestScore,
        cells: updatedCells,
      };
    }
    case EventDataActionTypes.SET_MATCH_CLOCK: {
      return {
        ...state,
        matchClock: payload.matchClock,
      };
    }
    case EventDataActionTypes.SET_GAMEBOARD_STATE: {
      return {
        ...state,
        gameBoardState: payload.gameBoardState,
      };
    }
    case EventDataActionTypes.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case EventDataActionTypes.FETCH_SQUARES_GAME_BY_ID_SUCCESS: {
      const squareGame = payload as SquareGame;
      squareGame.intervals.sort((a, b) => a.interval - b.interval);
      const awardedCells = updateAwardedCell(squareGame.squares);
      const latestScore = getInitScore(squareGame);
      if (squareGame.status === SquareGameStatus.Started) {
        const cellsToUpdate = awardedCells
          .concat(updateLiveCell(latestScore, state.eventScore));
        const updatedCells = mergeCells(cellsToUpdate, state.cells);
        return {
          ...state,
          squareGame,
          cells: updatedCells,
          eventScore: latestScore,
        };
      }

      if (squareGame.status === SquareGameStatus.Completed) {
        const lastCompletedInterval = getLastCompletedInterval(squareGame.intervals);
        if (!lastCompletedInterval) {
          logger.error('Game status completed but can not find completed interval. Game Data: ', squareGame);
          return state;
        }

        if (!lastCompletedInterval.winningSquare) {
          logger.error('Game status completed but can not find completed interval with winning square. Game Data: ', squareGame);
        }

        const winningSquare = lastCompletedInterval.winningSquare
          || Score.fromCoordinates(lastCompletedInterval.score).toSquare();
        const finalScore = Score.fromCoordinates(squareGame.latestScore);
        logger.debug(`Game with id: ${squareGame.gameId} is COMPLETED with the latestScore: ${squareGame.latestScore} and winningSquare: ${winningSquare}`);

        const {
          finalEventScore,
          cellsToUpdate,
        } = gameCompletedStateUpdates(winningSquare, finalScore, state.eventScore);

        const updatedCells = mergeCells(cellsToUpdate.concat(awardedCells), state.cells);
        return {
          ...state,
          squareGame,
          cells: updatedCells,
          eventScore: finalEventScore,
          winningSquare,
        };
      }
      const userAwardedUpdatedCells = mergeCells(awardedCells, state.cells);

      return {
        ...state,
        squareGame,
        cells: userAwardedUpdatedCells,
      };
    }
    case EventDataActionTypes.FETCH_PLAYER_EXTRA_FIELDS_SUCCESS: {
      const response = payload as {[key: string]: any};
      const { squareGame } = state;
      if (!squareGame) return state;
      const { eventId } = squareGame;
      const isBoostActive = Object.keys(response).includes(SQUARES_LOTTERY_BOOST_PREFIX + eventId);

      return {
        ...state,
        isBoostActive,
      } as EventDataState;
    }

    case EventDataActionTypes.EVENT_NOTIFICATION_SQUARE_AWARDED: {
      const awardedSquares = payload as Square[];

      const awardedSquareCells = updateAwardedCell(awardedSquares);
      const updatedCells = mergeCells(awardedSquareCells, state.cells);
      return {
        ...state,
        cells: updatedCells,
      };
    }
    case EventDataActionTypes.EVENT_NOTIFICATION_GAME_STATUS_CHANGE: {
      if (!state.squareGame) {
        return state;
      }
      const game = state.squareGame;
      const { status } = payload as GameStatusChangeNotification;

      if (status === SquareGameStatus.Completed) {
        const {
          winningSquare,
          latestScore: latestScoreCoordinates,
        } = payload as GameStatusChangeToCompleted;
        const finalScore = Score.fromCoordinates(latestScoreCoordinates!);
        logger.debug(`Game with id: ${game.gameId} is COMPLETED with the latestScore: ${latestScoreCoordinates} and winningSquare: ${winningSquare}`);

        const {
          finalEventScore,
          cellsToUpdate,
        } = gameCompletedStateUpdates(winningSquare!, finalScore, state.eventScore);

        const updatedCells = mergeCells(cellsToUpdate, state.cells);
        return {
          ...state,
          squareGame: {
            ...game,
            status,
          },
          eventScore: finalEventScore,
          cells: updatedCells,
          winningSquare,
        };
      }

      return {
        ...state,
        squareGame: {
          ...game,
          status,
        },
      };
    }
    case EventDataActionTypes.EVENT_NOTIFICATION_SET_EXTRA_FIELD: {
      const extraFieldKey = payload as string;
      const { squareGame } = state;
      if (!squareGame) return state;
      const { eventId } = squareGame;
      if (extraFieldKey !== SQUARES_LOTTERY_BOOST_PREFIX + eventId) return state;

      return {
        ...state,
        isBoostActive: true,
      } as EventDataState;
    }

    default:
      return state;
  }
};
