import React, { MouseEventHandler } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isWinnerSelector } from '../../../../store/selectors/eventDataSelector';
import Translations from '../../../../constants/translations';
import { TabType } from '../../../../constants/enums';

const Tab = styled.button`
  font-size:  ${({ theme }) => theme.fontSize.medium};
  font-weight: 500;
  border: none;
  background: transparent;
  padding-bottom: 30px
`;

const TabCurrent = styled(Tab)`
  text-decoration: underline solid ${({ theme }) => theme.colors.tab.underline};
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
`;

type TabTitleProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isCurrent: boolean;
  tab: TabType;
}

const getTranslation = (intl: IntlShape, tab: TabType, isWinner: boolean): string => {
  switch (tab) {
    case TabType.PRIZES: {
      const translation = isWinner ? Translations.YOUR_WINS : Translations.PRIZE;
      return intl.formatMessage(translation);
    }
    case TabType.BOOSTS:
      return intl.formatMessage(Translations.BOOSTS);
    default:
      return tab;
  }
};

function TabTitle({ onClick, isCurrent, tab }: TabTitleProps) {
  const isWinner = useSelector(isWinnerSelector);
  const intl = useIntl();
  const translatedTitle = getTranslation(intl, tab, isWinner);

  return isCurrent
    ? (
      <TabCurrent onClick={onClick} data-testid="tab-title-current-button">
        {translatedTitle}
      </TabCurrent>
    )
    : (<Tab onClick={onClick} data-testid="tab-title-button">{translatedTitle}</Tab>);
}

export default TabTitle;
