import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ModalHeader from '../Modal/ModalHeader';
import GameLayout from '../Game/GameLayout';
import { useErrorState } from '../../hooks';
import { layoutStateSelector } from '../../store/selectors/appConfigSelector';
import { LayoutState } from '../../constants/enums';
import Rule from '../Rule';

const LayoutContainer = styled.div.attrs({ 'data-testid': 'layout-container' })<{ isRuleOnly: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ theme: { colors: { modal: { backgroundStart, backgroundEnd } } } }) => `linear-gradient(180deg, ${backgroundStart} 0%, ${backgroundEnd} 100%)`};
  ${({ isRuleOnly }) => !isRuleOnly && 'padding: 0px 8px;'}
  transition: filter 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.textColor};
  font-family: "DM Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

const ModalContent = styled.div<{ isDisabled: boolean }>`
filter: ${({ isDisabled }) => (isDisabled ? 'brightness(0.5)' : 'brightness(1)')};
pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  overflow: auto;
  height: ${RSIUtils.detector.isMobileApp ? '100vh' : 'inherit'};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function AppLayout() {
  useErrorState();
  const layoutState = useSelector(layoutStateSelector);

  if (layoutState === LayoutState.RuleOnly) {
    const dragIcons = Array.from(document.getElementsByClassName('react-modal-sheet-drag-indicator') as HTMLCollectionOf<HTMLElement>);
    dragIcons.forEach((dragIcon) => {
      const element = dragIcon;
      element.style.visibility = 'hidden';
    });
    return (
      <LayoutContainer isRuleOnly>
        <Rule />
      </LayoutContainer>
    );
  }
  return (

    <LayoutContainer isRuleOnly={false}>
      <ModalContent isDisabled={layoutState === LayoutState.Rule}>
        <ModalHeader />
        <GameLayout />
      </ModalContent>
      <Rule />
    </LayoutContainer>
  );
}

export default AppLayout;
