import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { gameRulesSelector } from '../../store/selectors/eventDataSelector';
import { ReactComponent as Dices } from '../../assets/svgs/dices.svg';
import Translations from '../../constants/translations';

const StyledBackOfficeContent = styled.div`
  .rules-backoffice-content {
    min-height: 200px;
    svg {
      float: left;
      width: 40%;
    }
  }
  p {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSize.massive};
    color: ${({ theme }) => theme.colors.rule.title};
    border-bottom: 0px
  }

  h2 {
    font-weight: initial;
    padding-bottom: 20px;
    font-size: ${({ theme }) => theme.fontSize.extraLarge};
  }
`;

function BackOfficeContent() {
  const rules = useSelector(gameRulesSelector);
  const { TITLE, SUBHEADER } = Translations.RULES;
  const intl = useIntl();
  return (
    <StyledBackOfficeContent>
      <h1>{intl.formatMessage(TITLE)}</h1>
      <h2>{intl.formatMessage(SUBHEADER)}</h2>
      <div className="rules-backoffice-content">
        <Dices />
        <p>
          {rules}
        </p>
      </div>
    </StyledBackOfficeContent>
  );
}

export default BackOfficeContent;
