import { EventScore } from '../../interfaces';
import { GameCellCollection, Square, UpdateCell } from '../../interfaces/EventDataState';
import Score from '../../utils/score';

/**
 * gets changes to be made for current live cell and clearing old live cell
 * cells state should be updated using mergeCells
 * @param eventScore
 * @param oldEventScore
 */
export const updateLiveCell = (
  eventScore: EventScore,
  oldEventScore: EventScore | undefined,
): UpdateCell[] => {
  const cellsToUpdate: UpdateCell[] = [];
  if (oldEventScore) {
    const oldLiveCellToClear = Score.fromEventScore(oldEventScore)
      .toUpdateCell({ isCurrentScore: false });
    cellsToUpdate.push(oldLiveCellToClear);
  }
  const liveCell = Score.fromEventScore(eventScore)
    .toUpdateCell({ isCurrentScore: true });
  cellsToUpdate.push(liveCell);
  return cellsToUpdate;
};

/**
 * maps squares to updateCells
 * cells state should be updated using mergeCells
 * @param squares
 */
export const updateAwardedCell = (
  squares: Square[] = [],
): UpdateCell[] => {
  if (squares.length === 0) return [];

  return squares.map((square) => Score.fromSquare(square)
    .toUpdateCell({ isUserAwardedCell: true }));
};

/**
 * Adds final score to current live cell to winning square
 * @param square
 */
const addFinalScore = (
  square: Square,
) => [Score.fromSquare(square)
  .toUpdateCell({ isFinalScore: true })];

/**
 * updates currentCells to incubate all changes in cellsToUpdate
 * @param cellsToUpdate
 * @param currentCells
 */
export const mergeCells = (
  cellsToUpdate: UpdateCell[],
  currentCells: GameCellCollection = {},
) => {
  if (cellsToUpdate.length === 0) return currentCells;

  const nextCells = !currentCells ? {} : { ...currentCells };
  (cellsToUpdate).forEach(({
    coordinate,
    value: updatedGameCell,
  }) => {
    if (nextCells[coordinate]) {
      nextCells[coordinate] = { ...nextCells[coordinate], ...updatedGameCell };
    } else {
      nextCells[coordinate] = updatedGameCell;
    }
  });
  return nextCells;
};

/**
 * gets updated finalEventScore and cellsToUpdate list
 * cells state should be updated using mergeCells
 * @param winningSquare
 * @param finalScore
 * @param oldLiveScore
 */
export const gameCompletedStateUpdates = (
  winningSquare: Square,
  finalScore: Score,
  oldLiveScore: EventScore | undefined,
): { cellsToUpdate: UpdateCell[], finalEventScore: EventScore} => {
  const cellsToUpdate = updateLiveCell(Score.fromSquare(winningSquare).toEventScore(), oldLiveScore)
    .concat(addFinalScore(winningSquare));
  return {
    cellsToUpdate,
    finalEventScore: finalScore.toEventScore(),
  };
};
