import styled from 'styled-components';

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  .close-button {
    margin-right: -8px;
  }
  .modal-back-icon {
    &__button {
      &--flex {
        width: 110%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &--top-padding {
        padding-top: 3px;
      }
    }
  }
`;

export const StickyHeader = styled.header`
  position: sticky;
  top: 0px;
  height: 48px;
  font-size: ${({ theme }) => theme.fontSize.medium};
  z-index: 999;
  font-weight: 500;
`;
