import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { eventScoreSelector, homeTeamSelector } from '../../../store/selectors/eventDataSelector';
import { ReactComponent as LongArrowRightSvg } from '../../../assets/svgs/longArrowRight.svg';
import Coordinates from './Coordinates';

export const Bar = styled.div`
  display: flex;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.small};

  svg {
    position: absolute;
  }
`;

export const TeamName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  background: ${({ theme }) => theme.colors.teamName.background};
  border-radius: 18px;
  max-width: calc(30vw - 20px); /* max space for team name */
`;

const BarHoriz = styled(Bar)`
  margin: 0px 6px 0px 18px;

  .long-arrow-right {
    margin-top: 3px;
    margin-left: -11px
  }

  ${TeamName} {
    margin-left: 2px;
    padding-left: 12px;
    padding-right: 8px;
  }
`;

function CoordinateBarHoriz() {
  const scores = useSelector(eventScoreSelector);
  const homeTeam = useSelector(homeTeamSelector);
  const homeScore = scores?.homeScore;

  /*
   * The order of Score TeamName and svg is done in this way on purpose
   * They all have `position absolute`, so in this order they can stack on each other correctly
   */
  return (
    <BarHoriz>
      <Coordinates liveScore={homeScore} />
      <TeamName>
        {homeTeam?.name || 'Home Team'}
      </TeamName>
      <LongArrowRightSvg className="long-arrow-right" />
    </BarHoriz>
  );
}

export default CoordinateBarHoriz;
