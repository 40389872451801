import { MessageDescriptor } from 'react-intl';
import { BoostType } from './enums';

type KEY = 'BACK' | 'CLOSE' | 'RULES_MODAL_TITLE' | 'YOUR_WINS' | 'LIVE' | 'RULES' |'ERROR_LOADING' | 'PRIZE' | 'BOOSTS' | 'TAB_LOADING' | 'ACTIVATED';
type NEST_KEY = 'NOT_WIN' | 'RULES' | BoostType.LOTTERY;
type ALL_KEY =
  { [key in KEY]: MessageDescriptor }
  & { [key in NEST_KEY]: { [key: string]: MessageDescriptor } };

const Translations: ALL_KEY = {
  BACK: { id: 'BACK', defaultMessage: 'Back' },
  CLOSE: { id: 'BTN_SHEET_MODAL_CLOSE', defaultMessage: 'Close' },
  RULES_MODAL_TITLE: { id: 'RULES_MODAL_TITLE', defaultMessage: 'Rules' },
  YOUR_WINS: { id: 'YOUR_WINS', defaultMessage: 'YOUR WINS' },
  LIVE: { id: 'LIVE', defaultMessage: 'LIVE' },
  ERROR_LOADING: { id: 'ERROR_LOADING', defaultMessage: 'Failed to load Squares game...' },
  TAB_LOADING: { id: 'TAB_LOADING', defaultMessage: 'Loading...' },
  PRIZE: { id: 'PRIZE', defaultMessage: 'PRIZE' },
  BOOSTS: { id: 'BOOSTS', defaultMessage: 'BOOSTS' },
  ACTIVATED: { id: 'ACTIVATED', defaultMessage: 'ACTIVATED' },
  RULES: {
    TITLE: { id: 'RULE_TITLE', defaultMessage: '1. RECIEVING A SQUARE' },
    SUBHEADER: { id: 'RULE_SUBHEADER', defaultMessage: 'Up to 3 squares can be earned in this game' },
  },
  NOT_WIN: {
    TITLE: { id: 'NOT_WIN_TITLE', defaultMessage: 'Better luck next time!' },
    DESCRIPTION: { id: 'NOT_WIN_DESCRIPTION', defaultMessage: 'Unfortunately, your square did not win. Try using boosts next time to boost your chances of winning' },
    BUTTON: { id: 'NOT_WIN_BUTTON', defaultMessage: 'Check Out More Events' },
  },
  [BoostType.LOTTERY]: {
    TITLE: { id: `${BoostType.LOTTERY}_TITLE`, defaultMessage: 'Lottery Boost' },
    DESCRIPTION: { id: `${BoostType.LOTTERY}_DESCRIPTION`, defaultMessage: 'Place a $5 or greater Same Game Parlay on this game to earn the Lottery Boost and a chance at up to $10,000!' },
  },
};

export default Translations;
