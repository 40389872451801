import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { awayTeamSelector, eventScoreSelector } from '../../../store/selectors/eventDataSelector';
import { ReactComponent as LongArrowRightSvg } from '../../../assets/svgs/longArrowRight.svg';
import { Bar, TeamName } from './CoordinateBarHoriz';
import Coordinates, { Coordinate } from './Coordinates';

const BarVert = styled(Bar)`
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin:0px 10px 0px 14px;

  .long-arrow-down {
    margin-top: -2px;
    margin-left: -9px;
    transform: scale(1,-1);
  }

  ${TeamName} {
    transform: scale(-1,-1);
    padding-right: 14px;
    padding-left: 4px;
    margin-left: 4px;
  }

  ${Coordinate} {
    transform: rotate(-90deg);
  }
`;

/**
 * Same as CoordinateBarHoriz
 * with all elements rotated vertically
 */
function CoordinateBarVert() {
  const scores = useSelector(eventScoreSelector);
  const awayTeam = useSelector(awayTeamSelector);
  const awayScore = scores?.awayScore;

  return (
    <BarVert>
      <Coordinates liveScore={awayScore} />
      <TeamName>
        {awayTeam?.name || 'Away Team'}
      </TeamName>
      <LongArrowRightSvg className="long-arrow-down" />
    </BarVert>
  );
}

export default CoordinateBarVert;
