import { createSelector } from 'reselect';
import { LayoutState } from '../../constants/enums';
import {
  RootState,
} from '../../interfaces';

const selectSelf = (state: RootState) => state.appConfig;

export const squareGameIdSelector = createSelector(
  selectSelf,
  (appConfig) => appConfig.gameId,
);

export const cageSelector = createSelector(
  selectSelf,
  (appConfig) => appConfig.cage,
);

export const layoutStateSelector = createSelector(
  selectSelf,
  (appConfig) => appConfig.layoutState || LayoutState.Game,
);

export const isStandAloneSelector = createSelector(
  selectSelf,
  (appConfig) => !!appConfig.isStandAlone,
);
