import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ReactComponent as LeftCaretIconSvg } from '../../assets/svgs/leftCaret.svg';
import { ReactComponent as InfoIconSvg } from '../../assets/svgs/info.svg';
import { StickyHeader } from '../StyledElements';
import { setLayoutState } from '../../store/actions/appConfig';
import { LayoutState } from '../../constants/enums';
import ModalIconButton from './ModalIconButton';
import { closeGame } from '../../utils/eventBus';
import Translations from '../../constants/translations';

const ModalHeaderCtn = styled(StickyHeader)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.modal.text};
  backdrop-filter: blur(4px);
  svg {
    color: ${({ theme }) => theme.colors.modal.icon};
    margin-top: 4px;
  }
  .modal-info-icon {
    &__button {
      margin-left: auto;
    }
  }
`;

function ModalHeader() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleModalClose = () => {
    closeGame();
  };
  const handleRuleIconClick = () => {
    dispatch(setLayoutState(LayoutState.Rule));
  };

  const { isDesktop } = RSIUtils.detector;
  const { CLOSE, BACK } = Translations;
  return (
    <ModalHeaderCtn>
      <ModalIconButton onClick={handleModalClose} aria-label="Back" data-testid="modal-header-back-icon">
        <div className="modal-back-icon__button--flex">
          <LeftCaretIconSvg />
          <div className="modal-back-icon__button--top-padding">
            {intl.formatMessage(isDesktop ? CLOSE : BACK)}
          </div>
        </div>
      </ModalIconButton>
      <ModalIconButton className="modal-info-icon__button" data-testid="modal-header-info-icon" onClick={handleRuleIconClick} aria-label="Info">
        <InfoIconSvg />
      </ModalIconButton>
    </ModalHeaderCtn>
  );
}

export default ModalHeader;
