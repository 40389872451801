import React from 'react';
import { useSquareModuleConfig } from '../../hooks';
import SquarePromoSection from './SquarePromoSection';

function SquarePromo() {
  const config = useSquareModuleConfig();
  if (!config?.loadOnDemand) {
    return <SquarePromoSection />;
  }
  return null;
}
export default SquarePromo;
