import { DefaultTheme } from 'styled-components';

// Themes
const theme: DefaultTheme = {
  colors: {
    textColor: '#FFF',
    headerTextColor: '#FFFFFF',
    backgroundColor: 'linear-gradient(180deg, #1D355B 0%, #051029 100%)',
    modal: {
      backgroundStart: '#152950',
      backgroundEnd: '#051029',
      icon: '#FFFFFF',
      text: '#FFFFFF',
    },
    rule: {
      background: '#031029',
      title: '#FDB61B',
    },
    eventScore: {
      liveBackground: '#0DB45C',
    },
    tab: {
      underline: '#FDB61B',
    },
    teamName: {
      background: '#5D778F',
    },
    cell: {
      boarder: '#88B5FF',
      background: {
        live: '#0DB45C',
        userAwarded: '#FFFFFF',
        userAwardedAndLive: '#0DB45C',
        userAwardedAndWinning: '#FDB61B',
      },
      text: {
        userAwardedAndLive: '#0DB45C',
        userAwardedAndWinning: '#1E4D9D',
        userAwarded: '#1E4D9D',
        live: '#FFFFFF',
      },
      dividerLine: {
        userAwardedAndWinning: {
          color: '#1E4D9D',
        },
        live: {
          color: '#FFFFFF',
          opacity: '32%',
        },
        normal: {
          color: '#1529504a',
          opacity: '85%',
        },
      },
    },
    footerItem: {
      background: '#0E1F3B',
      boxShadow: 'inset 0px -3px 0px #121E3E, inset 0px -2px 8px rgba(255, 255, 255, 0.1)',
    },
    skeleton: {
      grayLoader: '#D3D3D3',
      grayLoaderHighlight: '#dedede',
    },
  },
  fontSize: {
    small: '10px',
    small1: '11px',
    regular: '12px',
    regular1: '13px',
    medium: '14px',
    medium1: '15px',
    large: '16px',
    extraLarge: '18px',
    massive: '24px',
    heroSmall: '36px',
    heroMedium: '52px',
  },
};
export default theme;
