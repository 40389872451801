export const DELIMITER = '-';
export const GAME_API = 'service/promosquares/player/games';

// portal websocket event
export const SQUARES_GAME_STATUS_CHANGE = 'SQUARES_GAME_STATUS_CHANGE';
export const SQUARES_INTERVAL_COMPLETED = 'SQUARES_INTERVAL_COMPLETED';
export const PROMOTION_AWARD = {
  eventName: 'PROMOTION_AWARD',
  squaresPromoType: 'SQUARES_GAME',
  setExtraFieldPromoType: 'SET_PLAYEREXTFIELD',
};

// BOOST player eligibility flag
export const SQUARES_LOTTERY_BOOST_PREFIX = 'SQUARES_LOTTERY_BOOST_';
