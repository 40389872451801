import { AxiosInstance } from 'axios';
import { EventScore, MatchClock } from '../../interfaces';
import { EventDataActionTypes } from '../actionTypes';
import { GameBoardState } from '../../constants/enums';
import { GameStatusChangeNotification, Square } from '../../interfaces/EventDataState';

export const setEventScore = (eventScore: EventScore) => ({
  type: EventDataActionTypes.SET_EVENT_SCORE,
  payload: { eventScore },
});

export const setMatchClock = (matchClock: MatchClock) => ({
  type: EventDataActionTypes.SET_MATCH_CLOCK,
  payload: { matchClock },
});

export const setGameBoardState = (gameBoardState: GameBoardState) => ({
  type: EventDataActionTypes.SET_GAMEBOARD_STATE,
  payload: { gameBoardState },
});

export const setErrorMessage = (errorMessage: string) => ({
  type: EventDataActionTypes.SET_ERROR_MESSAGE,
  payload: { errorMessage },
});
export const fetchSquaresGameById = (squareGameId: number, sessionId: string, apiUrl: string) => ({
  type: EventDataActionTypes.FETCH_SQUARES_GAME_BY_ID_REQUEST,
  retry: { retryNumber: 7, intervalLength: 2000 },
  useApi: (client: AxiosInstance) => {
    const options = {
      headers: {
        'X-Auth-Token': sessionId,
      },
    };
    return client.get(`${apiUrl}service/promosquares/player/games/${squareGameId}`, options);
  },
});

export const fetchPlayerExtraFields = (sessionId: string, apiUrl: string) => ({
  type: EventDataActionTypes.FETCH_PLAYER_EXTRA_FIELDS_REQUEST,
  retry: { retryNumber: 7, intervalLength: 2000 },
  useApi: (client: AxiosInstance) => {
    const options = {
      headers: {
        'X-Auth-Token': sessionId,
      },
    };
    return client.get(`${apiUrl}account/player`, options);
  },
});

export const addAwardedSquares = (awardedSquares: Square[]) => ({
  type: EventDataActionTypes.EVENT_NOTIFICATION_SQUARE_AWARDED,
  payload: awardedSquares,
});

export const setExtraField = (extraFieldKey: string) => ({
  type: EventDataActionTypes.EVENT_NOTIFICATION_SET_EXTRA_FIELD,
  payload: extraFieldKey,
});

export const setGameStatus = (GameStatusChangeData: GameStatusChangeNotification) => ({
  type: EventDataActionTypes.EVENT_NOTIFICATION_GAME_STATUS_CHANGE,
  payload: GameStatusChangeData,
});
