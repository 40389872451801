import React from 'react';
import { createRoot } from 'react-dom/client';
import { init, destroy } from './appModule';
import SquarePromo from './lib/components/promotion';
import { logger } from './lib/services';
import {
  getConfigsFromQueryParams, setupSquareGameOpenConfig, isStandAloneMode, setupWindowArgs,
  sendLoginEvent, setupStandAloneModeContext,
} from './lib/utils/standAloneModeHelper';
import env from './lib/utils/env';
import { getInitState, subscribeModalSheetEventBus } from './lib/utils/eventBus';

const { PROMO_BTN_SECTION_ID, REACT_APP_IDENTIFIER } = env;

/**
 * Look for the Square Promotion container (my account drop down)
 * Then render square game buttons in that container
 */
function renderSquarePromoSection() {
  const container = document.getElementById(PROMO_BTN_SECTION_ID || 'square-promo-section');
  if (container) {
    const root = createRoot(container);
    root.render(
      <SquarePromo />,
    );
  } else {
    logger.warn(`Could not find the element with id: ${PROMO_BTN_SECTION_ID || 'square-promo-section'} to mount square promo buttons.`);
  }
}

function initStandAloneApp() {
  const { gameId, cage, baseURL } = getConfigsFromQueryParams();
  const initialState = getInitState({ gameId: Number(gameId), cage });
  init({
    containerId: REACT_APP_IDENTIFIER as string,
    initialState,
  });
  logger.info('Square Game Opened Stand Alone, query params: ', { gameId, cage, baseURL });
}

export default {
  isStandAloneMode,
  squareGameStandAloneMode: {
    init: () => {
      // For MFE that will run stand alone mode, it doesn't know any portal context
      // And because there are rsi tools still have dependencies to portal
      // Need to set up some window args and portal module functions
      setupWindowArgs();
      setupStandAloneModeContext();
      sendLoginEvent();

      initStandAloneApp();
    },
  },
  squareGamePromo: {
    init: () => {
      // In portal, register button services and RsiEventBus listener
      window.addEventListener('DOMContentLoaded', renderSquarePromoSection);
      // set up variables that is needed in square openGame config
      setupSquareGameOpenConfig();
    },
  },
  squareGameModal: {
    init: () => {
      subscribeModalSheetEventBus(init, destroy);
    },
  },

};
