import React, { useCallback, useEffect, useState } from 'react';
import { GAME_API } from '../../constants';
import { useLoginStatusChangeEvent, useSquarePromoAwardWS } from '../../hooks';
import { SquarePromoItem } from '../../interfaces';
import { SquareAwardParameters } from '../../interfaces/EventDataState';
import { logger } from '../../services';
import { getAuthHeader } from '../../services/httpClient';
import { env } from '../../utils';
import { closeGame } from '../../utils/eventBus';
import { fetchWithPagination } from '../../utils/squareApi';
import SquarePromoList from './SquarePromoList';

function toggleSectionCss(show: boolean) {
  const element = document.getElementById(env.PROMO_BTN_SECTION_ID || 'square-promo-section');
  if (element) {
    if (show) {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  }
}

async function fetchSquareGamePromotions(
  apiUrl: string,
  setSquareGames: React.Dispatch<React.SetStateAction<SquarePromoItem[]>>,
) {
  try {
    const options = getAuthHeader();

    if (options) {
      const uncompletedGames = await fetchWithPagination(
        `${apiUrl}${GAME_API}`,
        {
          ...options,
          params: { mustBePlayer: true },
        },
        [],
      );

      const participatedGames = uncompletedGames?.filter((item) => item.squaresPlayed > 0);
      const completedGames = await fetchWithPagination(`${apiUrl}${GAME_API}/completed`, options, []);
      const allGames = participatedGames?.concat(completedGames) || [];
      setSquareGames(allGames);
    }
  } catch (e: unknown) {
    if (e && typeof e === 'object' && Object.keys(e)) {
      logger.error('Error while fetching square game promotions, error: ', e);
    }
  }
}

const mergeSquarePromoAward = (
  promoAwardWithCountOfSquares: SquareAwardParameters & { countOfSquaresAwarded: number },
  setSquareGames: React.Dispatch<React.SetStateAction<SquarePromoItem[]>>,
) => {
  const {
    gameId,
    eventId,
    name,
    countOfSquaresAwarded,
  } = promoAwardWithCountOfSquares;
  const awardGameId = Number(gameId);
  const awardEventId = Number(eventId);
  setSquareGames((existingPromos: SquarePromoItem[]) => {
    let awardExist = false;
    const promos = existingPromos.map((existingPromo) => {
      if (existingPromo.gameId === awardGameId && existingPromo.eventId === awardEventId) {
        awardExist = true;
        return {
          ...existingPromo,
          squaresPlayed: existingPromo.squaresPlayed + countOfSquaresAwarded,
        };
      }
      return existingPromo;
    });
    if (awardExist) {
      return promos;
    }
    return [
      ...promos,
      {
        gameId: awardGameId,
        eventId: awardEventId,
        name,
        squaresPlayed: countOfSquaresAwarded,
      }];
  });
};

function SquarePromoSection() {
  const [squareGamePromotions, setSquareGamePromotions] = useState<SquarePromoItem[]>([]);
  const memoMerge = useCallback((
    promoAwardWithCountOfSquares: SquareAwardParameters & { countOfSquaresAwarded: number },
  ) => mergeSquarePromoAward(promoAwardWithCountOfSquares, setSquareGamePromotions), []);

  useSquarePromoAwardWS(memoMerge);

  const fetchGames = useCallback(
    (apiUrl: string) => {
      fetchSquareGamePromotions(apiUrl, setSquareGamePromotions);
    },
    [],
  );

  useLoginStatusChangeEvent({
    reason: 'SQUARE-BUTTONS',
    onLogin: fetchGames,
    onLogout: closeGame,
  });

  useEffect(() => {
    toggleSectionCss(!!squareGamePromotions.length);
  }, [squareGamePromotions.length]);

  return squareGamePromotions.length
    ? <SquarePromoList squareGamePromotions={squareGamePromotions} />
    : null;
}

export default SquarePromoSection;
