import { Middleware } from 'redux';
import { RootState } from '../../interfaces';
import { SquareGameStatus } from '../../constants/enums';
import { EventDataActionTypes } from '../actionTypes';
import { GameStatusChangeNotification, SquareGame } from '../../interfaces/EventDataState';
import { postSquareGameConfirm } from '../../utils/squareApi';
import { rsiSquareGameConf } from '../../utils/standAloneModeHelper';
import { logger } from '../../services';

/**
 * Do a POST to server to mark game as confirmed, means user has viewed the result
 * It watches the following actions, and will trigger postSquareGameConfirm
 * - Current game changed to completed status
 * - Game fetch api returned a game in completed status
 * @param store redux store
 * @returns null
 */
const gameConfirmMiddleware: Middleware<{}, RootState> = (store) => (next) => (action): void => {
  next(action);
  const { type, payload } = action;
  const apiUrl = window.args?.apiUrl || rsiSquareGameConf.apiUrl || '/api/';
  if (type === EventDataActionTypes.EVENT_NOTIFICATION_GAME_STATUS_CHANGE) {
    const { status, gameId } = payload as GameStatusChangeNotification;
    if (status === SquareGameStatus.Completed
      && store.getState().eventData.squareGame?.gameId === gameId) {
      postSquareGameConfirm(apiUrl, gameId);
    }
  }

  if (type === EventDataActionTypes.FETCH_SQUARES_GAME_BY_ID_SUCCESS) {
    const { status, gameId } = payload as SquareGame;
    if (status === SquareGameStatus.Completed) {
      postSquareGameConfirm(apiUrl, gameId);
    }
  }

  if (!(window.args?.apiUrl || rsiSquareGameConf.apiUrl)) {
    logger.warn('apiUrl is not set in the config, using fallback /api/');
  }
};

export default gameConfirmMiddleware;
