import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Cell from './Cell';
import { getCoordinate } from '../../../utils/score';

// TODO from game config
export const SIZE = 10;
export const boardSizeArray = Array.from(Array(SIZE).keys());

const BoardContainer = styled.div<{ size: number, height: number }>`
  display: grid;
  grid-template-columns: repeat(${({ size }) => size}, 1fr);
  grid-template-rows: repeat(${({ size }) => size}, 1fr);
  gap: 1px;
  margin-top: -12px;
  width: calc(100% - 27px);
  ${({ height }) => (height > 0 ? `height: ${height}px;` : '')}
  margin-left: 18px;
  aspect-ratio: 1 / 1;
`;

function Board() {
  const [height, setHeight] = useState(0);
  const boardCtnRef = useRef<HTMLDivElement>(null);

  // aspect-ratio is not supported in lower version android webview
  // need to set the height same as width manually
  useEffect(() => {
    // Without this it returns:
    // If this ref points to a node rendered by React,
    // copy 'boardCtnRef.current' to a variable inside the effect,
    // and use that variable in the cleanup function.
    const refVar = boardCtnRef.current;
    const observer = new ResizeObserver((entries) => {
      setHeight(entries[0].contentRect.width);
    });
    if (refVar) {
      observer.observe(refVar);
    }

    return () => {
      if (refVar) {
        observer.unobserve(refVar);
      }
    };
  }, []);

  return (
    <BoardContainer ref={boardCtnRef} size={SIZE} height={height}>
      {boardSizeArray.map(
        (vertical) => boardSizeArray.map(
          (horizontal) => {
            const coordinate = getCoordinate(vertical, horizontal);
            return (
              <Cell
                key={coordinate}
                coordinate={coordinate}
              />
            );
          },
        ),
      )}
    </BoardContainer>
  );
}

export default Board;
