import { AxiosRequestConfig } from 'axios';
import { GAME_API } from '../constants';
import {
  ApiConfig,
  ModulesConfiguration,
  SquarePromoItem,
  SquarePromoResponse,
} from '../interfaces';
import httpClient, { getAuthHeader } from '../services/httpClient';
import { getConfigsFromQueryParams } from './standAloneModeHelper';
import { logger } from '../services';

export const getStorageSessionId = (cage?: string) => {
  if (cage) {
    return RSIBrowserStorage.get(`session-${cage}`);
  }
  const { cage: urlParamCage } = getConfigsFromQueryParams();
  if (urlParamCage) {
    return RSIBrowserStorage.get(`session-${urlParamCage}`);
  }
  return RSIBrowserStorage.get(`session-${window.args.cage}`);
};

/**
 * After player viewed the final result of game, send this POST to mark the game confirmed
 * @param apiUrl
 * @param gameId
 */
export async function postSquareGameConfirm(
  apiUrl: string,
  gameId: number,
) {
  const options = getAuthHeader();
  if (options) {
    try {
      await httpClient.post(`${apiUrl}${GAME_API}/${gameId}/confirm`, {}, options);
    } catch (e) {
      logger.error('Game confirm api call failed', e);
    }
  }
}

/**
 * Since the api is paginated, this is the helper to fetch all the records
 * @param url square game api
 * @param options axios config, it includes x-auth-token header
 * @param squarePromoItems square game item array
 * @returns square game item array
 */
export async function fetchWithPagination(
  url: string,
  options: AxiosRequestConfig,
  squarePromoItems: SquarePromoItem[] = [],
): Promise<SquarePromoItem[]> {
  try {
    const res = await httpClient.get(url, options);
    if (res.status === 200) {
      const resData = res.data as SquarePromoResponse;
      const { paging, items } = resData;
      const concatItems = squarePromoItems.concat(items);
      const { page, totalPages } = paging;
      if (page < totalPages) {
        return fetchWithPagination(url, {
          ...options,
          params: {
            ...options.params,
            pageNo: page + 1,
          },
        }, concatItems);
      }
      return concatItems;
    }
    // Will be used to debug (DEV) recurrent fetching error, to be removed in coming PR
    logger.info(`Successfully fetched but api responded with ${res.status} code`);
    return squarePromoItems;
  } catch (e) {
    throw new Error(`Failed at square game api: ${url}`);
  }
}

// These requests are not used in React. So not using useConfig hook
/**
 * Get modules configuration file
 * @returns ModulesConfiguration.json content
 */
export async function fetchModuleConfigs(): Promise<ModulesConfiguration> {
  return httpClient.get('/conf/modulesConfiguration.json').then(({ data }) => data, (error) => {
    throw error;
  });
}

export async function fetchApiConfigs(): Promise<ApiConfig> {
  return httpClient.get('/conf/api.json').then(({ data }) => data, (error) => {
    throw error;
  });
}
