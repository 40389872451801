import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SIZE } from './Board';
import { cellDataSelector } from '../../../store/selectors/eventDataSelector';
import { RootState } from '../../../interfaces';
import { ReactComponent as Trophy } from '../../../assets/svgs/trophy.svg';
import { ReactComponent as Live } from '../../../assets/svgs/live.svg';
import {
  CellContainer, CurrentScoreCell, UserAwardedCell, NormalCell, FinalScoreCell,
} from './StyledCells';
import Score from '../../../utils/score';

function getClassName(column: number, row: number) {
  let className = '';
  if (column === 0) {
    className += 'cell-left ';
  }
  if (row === (SIZE - 1)) {
    className += 'cell-bottom ';
  }
  if (column === (SIZE - 1)) {
    className += 'cell-right ';
  }
  if (row === 0) {
    className += 'cell-top ';
  }
  return className;
}

type CellProps = {
  coordinate: string
};

function Cell({ coordinate }: CellProps) {
  const cell = useSelector((state: RootState) => cellDataSelector(state, coordinate), shallowEqual);
  const {
    column,
    row,
  } = Score.fromCoordinates(coordinate);
  const {
    isCurrentScore, isUserAwardedCell, isFinalScore,
  } = cell;
  const className = getClassName(column, row);
  function generateCellContent() {
    if (isCurrentScore && isUserAwardedCell) {
      return (
        <UserAwardedCell isLive={isCurrentScore} isFinalScore={isFinalScore} className={className}>
          <span className="homeTeamScore">{column}</span>
          <div className="divider" />
          <span className="awayTeamScore">{row}</span>
        </UserAwardedCell>
      );
    }
    if (isFinalScore) {
      return (
        <FinalScoreCell className={className}>
          <Trophy />
        </FinalScoreCell>
      );
    }
    if (isCurrentScore) {
      return (
        <CurrentScoreCell className={className}>
          <Live />
        </CurrentScoreCell>
      );
    }
    if (isUserAwardedCell) {
      return (
        <UserAwardedCell isFinalScore={isFinalScore} className={className}>
          <span className="homeTeamScore">{column}</span>
          <div className="divider" />
          <span className="awayTeamScore">{row}</span>
        </UserAwardedCell>
      );
    }
    return <NormalCell className={className} />;
  }

  return (
    <CellContainer className={className}>
      {generateCellContent()}
    </CellContainer>
  );
}

export default memo(Cell);
