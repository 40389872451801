import QueryString from 'qs';
import { ModulesConfiguration } from '../interfaces';
import { logger } from '../services';
import env from './env';
import { fetchApiConfigs, fetchModuleConfigs } from './squareApi';

export const rsiSquareGameConf: {
  apiUrl?: string;
  cdnUrl?: string;
  baseURL?: string;
  cage?: string;
  useDockerImage?: boolean;
} = {};

export const getConfigsFromQueryParams = () => {
  const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
  const { gameId, cage, baseURL } = queryParams;
  return {
    gameId: gameId ? decodeURIComponent(String(gameId)) : gameId,
    cage: cage ? decodeURIComponent(String(cage)) : cage,
    baseURL: baseURL ? decodeURIComponent(String(baseURL)) : baseURL,
  };
};

export const isStandAloneMode = () => {
  const isMfeInPath = window.location.pathname.startsWith(`/${env.REACT_APP_IDENTIFIER}`);
  const { gameId } = getConfigsFromQueryParams();
  return isMfeInPath && !!gameId;
};

const setupSquaresModuleConfig = (modulesConfiguration: ModulesConfiguration) => {
  const { cdnDomain, internal } = modulesConfiguration;
  const squareModule = internal.find((cfg) => cfg.name === env.REACT_APP_IDENTIFIER);
  if (squareModule) {
    const { name, version, useDockerImage } = squareModule;
    const cdnUrl = `${cdnDomain}/${name}/${version}`;
    rsiSquareGameConf.cdnUrl = cdnUrl;
    rsiSquareGameConf.useDockerImage = useDockerImage;
  } else {
    logger.error('Failed to get square game config in modulesConfiguration.json');
  }
};

// Set up env values - CDN url, base url, cage code
export const setupSquareGameOpenConfig = async () => {
  const modulesConfiguration = await fetchModuleConfigs();
  const apiConfig = await fetchApiConfigs();
  if (modulesConfiguration) {
    setupSquaresModuleConfig(modulesConfiguration);
  }
  if (apiConfig) {
    rsiSquareGameConf.baseURL = apiConfig.baseUrl;
    rsiSquareGameConf.cage = apiConfig.cage;
    rsiSquareGameConf.apiUrl = apiConfig.apiUrl;
  }
};

export const setupWindowArgs = async () => {
  const apiConfig = await fetchApiConfigs();
  window.args = { ...window.args, ...apiConfig };
};

/**
 * In stand alone mode, it can access to portal modules.
 * rsi-logger has dependcy on portal rsiApi
 * Need to mock up rsiApi, RSINavigationHandler, to be used in rsi-logger
 * @returns void
 */
export const setupStandAloneModeContext = () => {
  try {
    if (window.args) {
      const { cage } = window.args;
      const getCagePlayerId = () => {
        try {
          const player = RSIBrowserStorage.get(`player-${cage}`);
          return `${player.CAGE_CODE}-${player.CAGE_PLAYER_ID}`;
        } catch {
          return null;
        }
      };

      window.rsiApi = {
        getPlayer: () => RSIBrowserStorage.get(`player-${cage}`),
        getSessionId: () => RSIBrowserStorage.get(`session-${cage}`),
        getLanguage: () => 'ENG',
        getPlayerIdWithCageCode: () => getCagePlayerId(),
      };

      window.RSINavigationHandler = { getCurrentPage: () => window.location.href };
      return;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to setup under stand alone mode context: ', { error });
  }
  setTimeout(() => setupStandAloneModeContext(), 500);
};

/**
 * Manually send a mock login Event for logger to pickup session id and player
 * @returns void
 */
export const sendLoginEvent = () => {
  if (window.RSIEventBus && window.rsiApi) {
    // send mock PORTAL_LOGIN_STATE_CHANGE event if session exists
    if (window.rsiApi.getSessionId()) {
      window.RSIEventBus.publish(
        RSIEventBus.eventTypes.PORTAL_LOGIN_STATE_CHANGE,
        { isLoggedIn: true, sessionId: window.rsiApi.getSessionId() },
        { withHistory: true, toNative: false },
      );
    }
    return;
  }
  setTimeout(() => sendLoginEvent(), 500);
};
