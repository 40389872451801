import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { useLocale, useTimeZone, useTranslations } from './hooks';
import Layout from './components/layout';
import defaultTheme from './themes/default';
import { TranslationType } from './constants/enums';

function App() {
  const timeZone = useTimeZone();
  const { locale } = useLocale();
  const translations = useTranslations(TranslationType.SquareGame);

  if (!locale || !timeZone) {
    return null;
  }

  return (
    <IntlProvider
      timeZone={timeZone}
      locale={locale}
      defaultLocale={locale}
      messages={translations}
    >
      <ThemeProvider theme={defaultTheme}>
        <Layout />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
