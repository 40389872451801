import { AnyAction } from 'redux';
import { AppConfig } from '../../interfaces';
import { AppConfigActionTypes } from '../actionTypes';
import { SquareGame } from '../../interfaces/EventDataState';
import { LayoutState } from '../../constants/enums';
import EventDataActionTypes from '../actionTypes/eventData';

const initialState: AppConfig = {};

export default (
  state = initialState,
  { payload, type }: AnyAction = { type: AppConfigActionTypes.DEFAULT },
) => {
  switch (type) {
    case AppConfigActionTypes.SET_LAYOUT_STATE: {
      return { ...state, layoutState: payload.layoutState };
    }
    case EventDataActionTypes.FETCH_SQUARES_GAME_BY_ID_SUCCESS: {
      const squareGame = payload as SquareGame;
      if (squareGame.squares.length === 0) {
        return {
          ...state,
          layoutState: LayoutState.RuleOnly,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
