import { LayoutState } from '../constants/enums';
import { AppConfig } from '../interfaces';
import { logger } from '../services';
import { rsiSquareGameConf } from './standAloneModeHelper';
import env from './env';

const { REACT_APP_IDENTIFIER } = env;

export type BottomSheetModalPayload = {
  identifier: string;
  containerId: string;
  config: AppConfig;
}

/**
 * Construct the initial store state of Square game from config
 * @param config the param passed in when open Game
 * @returns initial square game store state
 */
export const getInitState = (config: AppConfig) => ({
  appConfig: {
    gameId: Number(config.gameId),
    cage: config.cage,
  },
});
export const subscribeModalSheetEventBus = (init: Function, destroy: Function) => {
  RSIEventBus.subscribe(
    RSIEventBus.eventTypes.BOTTOM_SHEET_MODAL_OPENED,
    (payload: BottomSheetModalPayload) => {
      const { identifier, containerId, config } = payload;

      if (containerId && identifier === REACT_APP_IDENTIFIER) {
        init({
          containerId,
          initialState: getInitState(config),
        });
        logger.info('Received BOTTOM_SHEET_MODAL_OPENED event and init Squares Game in modal, ', containerId);
      }
    },
  );

  if (!RSIUtils.detector.isMobileApp) {
    RSIEventBus.subscribe(
      RSIEventBus.eventTypes.BOTTOM_SHEET_MODAL_CLOSED,
      ({
        containerId,
        identifier,
      }: BottomSheetModalPayload) => {
        if (containerId && identifier === REACT_APP_IDENTIFIER) {
          logger.info('Received BOTTOM_SHEET_MODAL_CLOSED and destroy Square Game in modal: ', containerId);
          destroy(containerId);
        }
      },
    );
  }
};

const triggerWebModalCloseEvent = () => {
  RSIEventBus.publish(
    RSIEventBus.eventTypes.CLOSE_BOTTOM_SHEET_MODAL,
    {
      identifier: REACT_APP_IDENTIFIER,
    },
    { withHistory: true },
  );
};

const triggerAppModalCloseEvent = () => {
  RSIEventBus.publish(
    RSIEventBus.eventTypes.CLOSE_BOTTOM_SHEET_MODAL,
    {
      identifier: REACT_APP_IDENTIFIER,
    },
    { toNative: true },
  );
};

const triggerWebModalOpenEvent = (config: AppConfig) => {
  const { isDesktop } = RSIUtils.detector;
  const modalHeight = window.innerHeight * 0.75;
  const modalWidth = 375;

  RSIEventBus.publish(
    RSIEventBus.eventTypes.OPEN_BOTTOM_SHEET_MODAL,
    {
      identifier: REACT_APP_IDENTIFIER,
      config,
      modalOptions: {
        enableBackdrop: false,
        noPadding: true,
        maxWidth: isDesktop && modalWidth,
        maxHeight: isDesktop && modalHeight,
        align: isDesktop && 'right',
      },
    },
    { withHistory: true },
  );
  logger.info('Square Game Client triggered open Web modal with args: ', { config });
};

const triggerAppModalOpenEvent = (appConfig: AppConfig) => {
  const cage = appConfig.cage || rsiSquareGameConf.cage!;
  const baseURL = appConfig.baseURL || rsiSquareGameConf.baseURL!;
  const config = { ...appConfig, baseURL, cage };
  // use square game Docker path
  let url = `${baseURL}${env.REACT_APP_IDENTIFIER}/`;
  // can use square game from CDN if regulated flag is off
  if (!rsiSquareGameConf.useDockerImage) {
    url = appConfig.cdnUrl || rsiSquareGameConf.cdnUrl!;
  }

  RSIEventBus.publish(
    RSIEventBus.eventTypes.OPEN_BOTTOM_SHEET_MODAL,
    {
      identifier: REACT_APP_IDENTIFIER,
      url: url[url.length - 1] !== '/' ? `${url}/` : url,
      config,
      sessionStorageKeys: [`session-${cage}`, `player-${cage}`],
    },
    { toNative: true },
  );
  logger.info('Square Game Client triggered open App modal with args: ', {
    config, url, ...rsiSquareGameConf,
  });
};

export const openGame = (config: AppConfig) => {
  if (RSIUtils.detector.isMobileApp) {
    triggerAppModalOpenEvent(config);
  } else {
    triggerWebModalOpenEvent(config);
  }
};

export const openRule = () => {
  const configs = { layoutState: LayoutState.RuleOnly };
  if (RSIUtils.detector.isMobileApp) {
    triggerAppModalOpenEvent(configs);
  } else {
    triggerWebModalOpenEvent(configs);
  }
};

export const closeGame = () => {
  if (RSIUtils.detector.isMobileApp) {
    triggerAppModalCloseEvent();
  } else {
    triggerWebModalCloseEvent();
  }
};
