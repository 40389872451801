import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../services';
import {
  SportsWssResponse, EventScoreResponse, MatchClockResponse,
} from '../interfaces';
import { setEventScore, setMatchClock } from '../store/actions/eventData';
import { eventIdSelector, gameStatusSelector } from '../store/selectors/eventDataSelector';
import useConfigs from './useConfigs';
import { SquareGameStatus } from '../constants/enums';

let subscription: { unsubscribe: Function, send: Function } | null = null;

const EVENT_SCORE_UPDATED = 'EVENT_SCORE_UPDATED';
const MATCH_CLOCK_UPDATED = 'MATCH_CLOCK_UPDATED';

const EVENT_SCORE_SUBSCRIBE = 'EVENT_SCORE_SUBSCRIBE';
const MATCH_CLOCK_SUBSCRIBE = 'MATCH_CLOCK_SUBSCRIBE';

const MATCH_CLOCK_UNSUBSCRIBE = 'MATCH_CLOCK_UNSUBSCRIBE';
const EVENT_SCORE_UNSUBSCRIBE = 'EVENT_SCORE_UNSUBSCRIBE';

const SPORT_WSS = '/service/sportsbook/offering/ws';

const wsConfig = {
  messages: [MATCH_CLOCK_UPDATED, EVENT_SCORE_UPDATED],
  onOpen: () => { },
  onClose: () => {
    // dispatch warnings
  },
};

const useSportWS = () => {
  const { api } = useConfigs(['api']);
  const eventId = useSelector(eventIdSelector);
  const gameStatus = useSelector(gameStatusSelector);
  const dispatch = useDispatch();

  const unsubscribe = useCallback(() => {
    if (subscription) {
      subscription.send({
        messageType: MATCH_CLOCK_UNSUBSCRIBE,
        id: eventId,
      });
      subscription.send({
        messageType: EVENT_SCORE_UNSUBSCRIBE,
        id: eventId,
      });
      subscription.unsubscribe();
      subscription = null;
      logger.info('unsubscribe sport event:', { eventId });
    }
  }, [eventId]);

  useEffect(() => {
    if (!gameStatus || !eventId || !api) {
      return;
    }
    if (![SquareGameStatus.Available, SquareGameStatus.Started].includes(gameStatus)) {
      unsubscribe();
      return;
    }
    if (!subscription) {
      const { cageCode, wssUrl } = api;
      const url = wssUrl.replace('/service/ws/', `${SPORT_WSS}/?cageCode=${cageCode}`);
      const onMessage = (payload: SportsWssResponse) => {
        const { messageType } = payload;
        switch (messageType) {
          case EVENT_SCORE_UPDATED: {
            const { eventScore } = payload as EventScoreResponse;
            if (eventScore) {
              logger.info(`Square game received sport score update: ${eventScore} for event id: ${eventId} `);
              dispatch(setEventScore(eventScore));
            }

            break;
          }
          case MATCH_CLOCK_UPDATED: {
            const { matchClock } = payload as MatchClockResponse;
            if (matchClock) {
              logger.info(`Square game received sport clock update: ${matchClock} for event id ${eventId}`);
              dispatch(setMatchClock(matchClock));
            }
            break;
          }
          default: {
            break;
          }
        }
      };
      // @ts-ignore
      window.onScoreMessage = onMessage;
      try {
        subscription = RSIWebSocket.subscribe({
          ...wsConfig,
          url,
        }, onMessage);

        subscription!.send({
          messageType: EVENT_SCORE_SUBSCRIBE,
          id: eventId,
        });
        subscription!.send({
          messageType: MATCH_CLOCK_SUBSCRIBE,
          id: eventId,
        });
        logger.info('square game subscribe to sportbook wss', { eventId });
      } catch (error) {
        // @ts-ignore Rollup build complain somehow, TS2554: Expected 0-1 arguments, but got 2
        throw new Error('Error in setup sportbook wss subscription: ', { cause: error as Error });
      }
    }
  }, [api, dispatch, eventId, gameStatus, unsubscribe]);

  useEffect(
    () => () => {
      unsubscribe();
    },
    [unsubscribe],
  );
};

export default useSportWS;
