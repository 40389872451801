import {
  openGame,
  openRule,
} from './lib/utils/eventBus';
import {
  version,
  instances,
  init,
  destroy,
} from './appModule';
import services from './services';
import { rsiSquareGameConf } from './lib/utils/standAloneModeHelper';

// check if Square Game is loaded stand alone
if (services.isStandAloneMode()) {
  services.squareGameStandAloneMode.init();
} else {
  services.squareGamePromo.init();
  services.squareGameModal.init();
}

export {
  version,
  instances,
  init,
  destroy,
  openGame,
  openRule,
  rsiSquareGameConf,
};
