import React from 'react';
import styled from 'styled-components';
import { openGame } from '../../utils/eventBus';
import { ReactComponent as UnknownSquareIcon } from '../../assets/svgs/unknownSquare.svg';
import { SquarePromoItem } from '../../interfaces';
import { useConfigs } from '../../hooks';

const SquareButton = styled.a`
  height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  svg {
    width: 16%;
    margin-top: -2px;
    vertical-align: middle;
    display: inline-block;
  }
  span {
    vertical-align: middle;
  }
`;

function SquarePromoBtn({ square }: { square: SquarePromoItem }) {
  const { api } = useConfigs(['api']);
  const { gameId, name } = square;
  const onClick = () => {
    openGame({
      gameId,
      baseURL: api?.baseUrl || window.args.baseUrl,
      cage: api?.cage || window.args.cage,
    });

    if (RSIUtils.detector.isMobileApp) {
      // inform IOS top nav bar to change the dropdown indicator as closed
      RSIEventBus.publish(
        RSIEventBus.eventTypes.RSI_TOP_NAVIGATION_CLOSE_DROPDOWNS,
        {},
        { toNative: true },
      );
      // TODO: Need a better fix. Let the IOS instruct portal to remove lock
      // After square modal sheet close, the portal scroll is still locked,
      // Remove the lock on button click
      document.documentElement.classList.remove('noscroll-dropdown');
    }
  };

  return (
    <SquareButton
      className="btn btn-block btn-lg btn-primary"
      data-testid={`squares-promo-btn-${gameId}`}
      key={gameId}
      type="button"
      onClick={onClick}
    >
      <UnknownSquareIcon />
      <span>{name}</span>
    </SquareButton>
  );
}

export default SquarePromoBtn;
