import React from 'react';
import styled from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetPlayerExtraFields, usePortalWS, useSportWS, useSquareGameData,
} from '../../hooks';
import Footer from './footer';
import GameBoard from './gameBoard';
import Header from './header';
import defaultTheme from '../../themes/default';
import { gameBoardStateSelector } from '../../store/selectors/eventDataSelector';
import { GameBoardState } from '../../constants/enums';

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 92%;
  color: ${({ theme }) => theme.colors.textColor};
`;

const HeaderContainer = styled.div`
  flex-shrink: 0;
  height: 56px;
`;

const BoardContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  padding-top: 8px;
  position: relative;
`;

const FooterContainer = styled.div`
  padding-top: 8px;
`;

function GameLayout() {
  useSquareGameData();
  usePortalWS();
  useSportWS();
  useGetPlayerExtraFields();

  const boardStatus = useSelector(gameBoardStateSelector);

  if (boardStatus === GameBoardState.LOADING) {
    return (
      <GameContainer data-testid="squares-game-layout-container">
        <SkeletonTheme
          baseColor={defaultTheme.colors.skeleton.grayLoader}
          highlightColor={defaultTheme.colors.skeleton.grayLoaderHighlight}
        >
          <HeaderContainer>
            <Skeleton height="100%" />
          </HeaderContainer>
          <BoardContainer>
            <Skeleton height="100%" />
          </BoardContainer>
          <FooterContainer>
            <Skeleton height="30vh" />
          </FooterContainer>
        </SkeletonTheme>
      </GameContainer>
    );
  }
  if (boardStatus === GameBoardState.ACTIVE) {
    return (
      <GameContainer data-testid="squares-game-layout-container">
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <BoardContainer>
          <GameBoard />
        </BoardContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </GameContainer>
    );
  }
  return null;
}

export default GameLayout;
