import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../services';
import { PortalWssResponse } from '../interfaces';
import { squareGameIdSelector } from '../store/selectors/appConfigSelector';
import useConfigs from './useConfigs';
import { addAwardedSquares, setExtraField, setGameStatus } from '../store/actions/eventData';
import {
  AwardedSquareNotification,
  AwardParameters,
  GameStatusChangeNotification,
  SetExtraFieldAwardParameters,
  Square,
} from '../interfaces/EventDataState';
import {
  SQUARES_GAME_STATUS_CHANGE,
  SQUARES_INTERVAL_COMPLETED,
  PROMOTION_AWARD,
} from '../constants';
import { onPromotionAwardReceived } from './useSquarePromoAwardWS';
import { getStorageSessionId } from '../utils/squareApi';

let subscription: { unsubscribe: Function, send: Function };

const wsConfig = {
  messages: [
    SQUARES_GAME_STATUS_CHANGE,
    SQUARES_INTERVAL_COMPLETED,
    PROMOTION_AWARD.eventName,
  ],
  onOpen: () => { },
  onClose: () => { },
};

const usePortalWS = () => {
  const { api } = useConfigs(['api']);
  const gameId = useSelector(squareGameIdSelector);
  const sessionId = getStorageSessionId();

  const dispatch = useDispatch();

  useEffect(() => {
    const onSetExtraField = (promotionAwardEvent: AwardedSquareNotification) => {
      const { awardParameters } = promotionAwardEvent;
      const { KEY } = awardParameters as SetExtraFieldAwardParameters;

      const isValid = () => {
        const { providerPromotionTypeCode } = promotionAwardEvent;
        const { setExtraFieldPromoType } = PROMOTION_AWARD;
        if (providerPromotionTypeCode !== setExtraFieldPromoType) return false;
        return !!KEY;
      };

      if (!isValid()) return;

      dispatch(setExtraField(KEY));
    };

    if (gameId && api) {
      const { wssUrl } = api;

      /**
       * This call back is part of the portalWS subscription which handles Sqaures
       * specific event notifications
       * @callback onMessage
       * @param payload
       */
      const onMessage = (payload: PortalWssResponse) => {
        const { type, data } = payload;
        switch (type) {
          case SQUARES_GAME_STATUS_CHANGE: {
            const gameStatusChangeEvent = data as GameStatusChangeNotification;
            if (gameStatusChangeEvent.gameId === gameId) {
              logger.info(`Game status changed to ${gameStatusChangeEvent.status} for game: ${gameId}`);
              dispatch(setGameStatus(gameStatusChangeEvent));
            }
            break;
          }
          case PROMOTION_AWARD.eventName: {
            const notificationData = data as AwardedSquareNotification;
            const addSquareOnGameBoard = (
              _ignore: AwardParameters,
              squares: Square[],
            ) => dispatch(addAwardedSquares(squares));

            onPromotionAwardReceived(notificationData, addSquareOnGameBoard, gameId);
            onSetExtraField(notificationData);
            break;
          }
          case SQUARES_INTERVAL_COMPLETED: {
            // dispatch(updateStoreByData(payload));
            break;
          }
          default: {
            break;
          }
        }
      };

      // @ts-ignore
      window.onPortalMessage = onMessage;

      try {
        subscription?.unsubscribe();
        subscription = RSIWebSocket.subscribe({
          ...wsConfig,
          url: wssUrl + sessionId,
        }, onMessage);
        logger.info('Subscribe from portal websocket:', { gameId });
      } catch (error) {
        // @ts-ignore Rollup build complain somehow, TS2554: Expected 0-1 arguments, but got 2
        throw new Error('Error in setup portal wss subscription: ', { cause: error as Error });
      }
    }
  }, [dispatch, gameId, api, sessionId]);

  useEffect(
    () => () => {
      if (subscription) {
        subscription.unsubscribe();
        logger.info('Unsubscribe from portal websocket:', { gameId });
      }
    },
    [gameId],
  );
};

export default usePortalWS;
