import { useState, useEffect } from 'react';
import { ModuleConfig } from '../interfaces';
import { env } from '../utils';
import useConfigs from './useConfigs';

const useSquareModuleConfig = () => {
  const [config, setConfig] = useState<ModuleConfig>();
  const { modulesConfiguration } = useConfigs(['modulesConfiguration']);
  useEffect(() => {
    if (modulesConfiguration) {
      const { internal } = modulesConfiguration;
      const squareModule = internal.filter((cfg) => cfg.name === env.REACT_APP_IDENTIFIER);
      if (squareModule.length === 1) {
        setConfig(squareModule[0]);
      }
    }
  }, [modulesConfiguration, setConfig]);

  return config;
};

export default useSquareModuleConfig;
