import { SquareGameStatus } from '../constants/enums';
import {
  Interval,
  Square,
  SquareGame,
} from '../interfaces/EventDataState';
import Score from './score';
import { EventScore } from '../interfaces';

export { default as applyMixins } from './applyMixins';
export { default as env } from './env';

export const getLastInterval = (intervals: Interval[]) => {
  const len = intervals.length;
  return intervals[len - 1];
};

export const getLastCompletedInterval = (intervals: Interval[]): Interval | undefined => {
  const len = intervals.length;
  let current = len - 1;
  while (current >= 0 && intervals[current].status !== SquareGameStatus.Completed) {
    current -= 1;
  }
  return intervals[current];
};

export const getActiveInterval = (
  intervals: Interval[],
) => {
  const intvls = intervals.filter(
    (intvl: Interval) => intvl.status === SquareGameStatus.Started,
  );

  if (intvls.length > 1) {
    throw new Error('Found more than one intervals with active status');
  }
  if (intvls.length === 0) {
    return undefined;
  }
  return intvls[0];
};

export const isAwardedSquaresMatchScore = (
  userAwardedSquares: Square[],
  latestScore: EventScore,
  winningSquare: Square | undefined,
) => {
  const {
    column,
    row,
  } = winningSquare || Score.fromEventScore(latestScore)
    .toSquare();
  return userAwardedSquares
    .some((eachSquare) => (eachSquare.column === column && eachSquare.row === row));
};

export const getInitScore = (squareGame: SquareGame) => {
  const { eventId, latestScore } = squareGame;
  const eventScore = {
    eventId,
    homeScore: '0',
    awayScore: '0',
  };

  return { ...eventScore, ...Score.fromCoordinates(latestScore).toEventScore() };
};
