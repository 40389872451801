enum EventDataActionTypes {
  SET_EVENT_SCORE = 'eventData/SET_EVENT_SCORE',
  SET_MATCH_CLOCK = 'eventData/SET_MATCH_CLOCK',
  SET_GAMEBOARD_STATE = 'eventData/SET_GAMEBOARD_STATUS',
  SET_ERROR_MESSAGE = 'eventData/SET_ERROR_MESSAGE',

  EVENT_NOTIFICATION_SQUARE_AWARDED = 'eventData/EVENT_NOTIFICATION_SQUARE_AWARDED',
  EVENT_NOTIFICATION_GAME_STATUS_CHANGE = 'eventData/EVENT_NOTIFICATION_GAME_STATUS_CHANGE',
  EVENT_NOTIFICATION_SET_EXTRA_FIELD = 'eventData/EVENT_NOTIFICATION_SET_EXTRA_FIELD',

  FETCH_SQUARES_GAME_BY_ID_REQUEST = 'eventData/FETCH_SQUARES_GAME_BY_ID_REQUEST',
  FETCH_PLAYER_EXTRA_FIELDS_REQUEST = 'eventData/FETCH_PLAYER_EXTRA_FIELDS_REQUEST',
  FETCH_PLAYER_EXTRA_FIELDS_SUCCESS = 'eventData/FETCH_PLAYER_EXTRA_FIELDS_SUCCESS',
  FETCH_SQUARES_GAME_BY_ID_SUCCESS = 'eventData/FETCH_SQUARES_GAME_BY_ID_SUCCESS',
  FETCH_SQUARES_GAME_BY_ID_FAILURE = 'eventData/FETCH_SQUARES_GAME_BY_ID_FAILURE',

  DEFAULT = 'eventData/DEFAULT',
}

export default EventDataActionTypes;
