import React from 'react';
import Board from './Board';
import CoordinateBarVert from './CoordinateBarVert';
import CoordinateBarHoriz from './CoordinateBarHoriz';

function GameBoard() {
  return (
    <>
      <CoordinateBarHoriz />
      <CoordinateBarVert />
      <Board />
    </>
  );
}

export default GameBoard;
