import axios from 'axios';
import env from '../utils/env';
import { getStorageSessionId } from '../utils/squareApi';

const httpClient = axios.create({
  baseURL: axios.defaults.baseURL || env.REACT_APP_AXIOS_BASE_URL,
  params: {},
});

export function getAuthHeader() {
  try {
    const sessionId = getStorageSessionId();
    if (sessionId) {
      return {
        headers: {
          'X-Auth-Token': sessionId,
        },
      };
    }
    return null;
  } catch (err) {
    throw new Error(`Error while fetching session id from storage, error: ${err}`);
  }
}

export default httpClient;
