import { EventScore } from '../interfaces';
import { DELIMITER } from '../constants';
import { GameCell, Square, UpdateCell } from '../interfaces/EventDataState';

export const getCoordinate = (row: number, column: number) => `${row}${DELIMITER}${column}`;

export default class Score {
  static default() {
    return new Score(0, 0);
  }

  /**
   * Get Score from coordinates
   * @param scoreCoordinates: in format row-column
   */
  static fromCoordinates(scoreCoordinates: string): Score {
    if (scoreCoordinates === '' || scoreCoordinates === undefined || scoreCoordinates === null) return Score.default();

    const [awayScore, homeScore] = scoreCoordinates.split(DELIMITER);
    return new Score(Number(awayScore), Number(homeScore));
  }

  /**
   * Get Score from EventScore object
   * @param eventScore
   */
  static fromEventScore(eventScore: EventScore): Score {
    const { homeScore, awayScore } = eventScore;
    return new Score(Number(awayScore), Number(homeScore));
  }

  /**
   * Get Score from Square object
   * @param square
   */
  static fromSquare(square: Square): Score {
    const { row, column } = square;
    return new Score(Number(row), Number(column));
  }

  readonly row: number;

  readonly column: number;

  private constructor(away: number, home: number) {
    this.row = away;
    this.column = home;
  }

  toEventScore() {
    return { awayScore: String(this.row), homeScore: String(this.column) } as EventScore;
  }

  toSquare(): Square {
    return { row: Number(this.row % 10), column: Number(this.column % 10) };
  }

  isEqual(score: Score): boolean {
    if (!score) return false;
    return !!score.column && !!score.row
      && (score.column % 10) === this.column && (score.row % 10) === this.row;
  }

  toUpdateCell(gameCell: GameCell): UpdateCell {
    const coordinate = getCoordinate(this.row % 10, this.column % 10);
    return { coordinate, value: gameCell };
  }
}
