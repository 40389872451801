import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocale } from '../../../hooks';
import { eventStartTimeSelector, homeTeamSelector, awayTeamSelector } from '../../../store/selectors/eventDataSelector';

const DATE_FORMAT = {
  WITH_IN_WEEK: 'ddd h:mm A',
  OUT_OF_WEEK: 'MMM D, h:mm A',
};

const Container = styled.div<{ isNeedsResizing: boolean}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .event-name-row {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
  }

  .event-name {
    font-size: ${({ isNeedsResizing, theme }) => (isNeedsResizing ? theme.fontSize.medium : theme.fontSize.extraLarge)};
    font-weight: bold;
    width: 50%;
    max-height: 40px;
    overflow: hidden;
  }

  .middle-symbol {
    padding-right: 10px;
    padding-left: 10px
  }

  .home-team {
    text-align: left;
  }

  .away-team {
    text-align: right;
  }

  time {
    margin: auto;
    color: ${({ theme }) => theme.colors.modal.icon};
  }
`;

const currentDateTime = new RSIDateTime().currentDateTime.toISOString();

function EventName() {
  const { locale } = useLocale();
  const eventStartTime = useSelector(eventStartTimeSelector);
  const homeTeamName = useSelector(homeTeamSelector)!.name;
  const awayTeamName = useSelector(awayTeamSelector)!.name;
  const isNeedsResizing = (homeTeamName.length > 15 || awayTeamName.length > 15);

  const format = RSIDateTime.isSame({
    timeStamps: [eventStartTime, currentDateTime],
    unit: 'week',
  }) ? DATE_FORMAT.WITH_IN_WEEK : DATE_FORMAT.OUT_OF_WEEK;

  return (
    <Container isNeedsResizing={isNeedsResizing}>
      <div className="event-name-row">
        <div className="event-name away-team">{awayTeamName}</div>
        <div className="middle-symbol">@</div>
        <div className="event-name home-team">{homeTeamName}</div>
      </div>
      <time dateTime={eventStartTime} data-testid="event-name-time-container">
        {new RSIDateTime(eventStartTime).formatDateTime({
          format,
          options: {
            locale,
          },
        })}
      </time>
    </Container>
  );
}

export default EventName;
